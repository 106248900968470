import { Injectable } from "@angular/core";
import {
	ActivatedRouteSnapshot,
	CanActivate,
	RouterStateSnapshot,
	UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { Global } from "../_constants/global.variables";


@Injectable({
	providedIn: "root",
})
export class
DashboardAccessGuard implements CanActivate {
	constructor() {}
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		let selectedDashboardId = route.params.id;
		let ifAllowed = false;
		let foundDashboard = Global?.User?.currentUser?.Dashboards?.find(
			(obj) => obj.Id == selectedDashboardId
		);

		if (foundDashboard != undefined) {
			ifAllowed = true;
		} else {
			ifAllowed = false;
		}

		return ifAllowed;
	}
}
