<div class="content">
			<div class="col-md-12" *ngIf="viewReportsList" style="height: 100%">
				<div class="card" style="height: calc(-95px + 100vh)">
					<div class="card-header">
						<div class="col-md-4 text-left"></div>
						<h3 class="card-title col-md-4 text-center">Reports</h3>
						<div *ngIf="global.Data.footerDataSource === 'Test'"  [style.background-color]="'red'">
							<p  [style.color]="'white !important'">Note: Data source for reports is from production, your application is looking to test. Reports generated here and compared to test data may not match as test data doesn't always match prod data. </p>
						</div>

						<!-- <div class="col-md-4 text-right">
							<button class="btn btn-primary btn-round btn-icon mr-1 pull-right" tooltip="New Report">
								<i class="tim-icons icon-simple-add"> </i>
							</button>
						</div> -->
					</div>
					<div class="card-body" *ngIf="!customerReportsLoading" style="padding: 0 0 10px 0; height: 90%;">
						<kendo-grid [data]="gridViewReports" [groupable]="true" [group]="groups" [style.height.%]="100" [skip]="0" [navigable]="true" filterable="menu" [sortable]="true" [reorderable]="true" [resizable]="true" #grid>
							<kendo-grid-column field="CategoryName" title="CategoryName" [filterable]="true"></kendo-grid-column>
							<kendo-grid-column field="CreatedByName" title="CreatedBy"> </kendo-grid-column>
							<kendo-grid-column field="Name" title="Name"></kendo-grid-column>
							<kendo-grid-column field="Action" title="Action" [filterable]="false">
								<ng-template kendoGridCellTemplate let-report>
									<div style="white-space: nowrap">
										<button class="btn btn-info btn-link btn-sm" tooltip="Execute" type="button" (click)="execute(report)">
											<i class="tim-icons icon-triangle-right-17" [style.color]="global.Theme == 'light'? 'black': 'white'"></i>
										</button>
									</div>
								</ng-template>
							</kendo-grid-column>
						</kendo-grid>
					</div>
				</div>
			</div>

			<div class="col-md-12" *ngIf="displayReport" style="height: 100%;">
				<div class="card" style="height: calc(-95px + 100vh)">
					<div class="card-header">
						<div class="col-md-4 text-left">

						</div>
						<h4 class="card-title col-md-4 text-center">
							<strong>{{ currentReportName }}</strong>
						</h4>
						<div class="col-md-4 text-right">
							<button class="btn btn-icon btn-simple btn-twitter">
								<i class="tim-icons icon-minimal-left" (click)="reportList()"> </i>
							</button>
						</div>
					</div>
					<div class="card-body">
						<div class="table-responsive table-container">
							<tr-viewer
								[containerStyle]="viewerContainerStyle"
								[reportServer]="{
									url: 'https://stage.reporting.iopspro.com:443',
									username: 'guest'
								}"
								[reportSource]="this.reportSourceObject"
								[viewMode]="'INTERACTIVE'"
								[authenticationToken]="accessToken"
								[scaleMode]="'SPECIFIC'"
								[scale]="1.0"
							>
							</tr-viewer>
						</div>
					</div>
				</div>
			</div>

</div>
