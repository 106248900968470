import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { tap } from "rxjs/operators";

import { process, State, CompositeFilterDescriptor, distinct, GroupDescriptor } from "@progress/kendo-data-query";

import { Global } from 'projects/shared-lib/src/lib/_constants/global.variables';
import { DataService } from 'projects/shared-lib/src/lib/services/data.service';
import { IGlobal } from 'projects/shared-lib/src/lib/_models/global.model';


@Component({
  selector: "app-customer-reports",
  templateUrl: "./customer-reports.component.html",
  styleUrls: ["./customer-reports.component.scss"],
})
export class CustomerReportsComponent implements OnInit {
  customerReportsLoading = true;
  viewerContainerStyle = {
    position: "relative",
    width: "100%",
    height: "100%",
    ["font-family"]: "ms sans serif",
  };
  public global: IGlobal = Global;
  accessToken: string;
  categories: any;
  reports: any;
  public gridViewReports: any;
  public state: State = { skip: 0 };
  public reportsState: State = { skip: 0 };
  public viewReportsList: boolean = true;
  public displayReport: boolean = false;
  public reportSourceObject: any;
  public groups: GroupDescriptor[] = [{ field: "CategoryName" }];
  currentReportName: string;
  public userReportCategories: any;

  constructor(private http: HttpClient, private dataService: DataService) { }

  execute(report) {
    this.currentReportName = report.CategoryName + "/" + report.Name;
    this.reportSourceObject = {
      report: report.CategoryName + "/" + report.Name,
      parameters: {
        accessToken: this.global.User.currentUser.AuthenticationTokenFromUserRecord,
      },
    };
    console.log(report);
    this.viewReportsList = false;
    this.displayReport = true;
  }

  reportList(refresh?: boolean) {
    //console.log("going back to reports list...");
    this.viewReportsList = true;

    this.displayReport = false;
  }

  ngOnInit() {
    let httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "",
    });
    let httpOptions = {
      headers: httpHeaders,
      observe: "response" as "response",
      reponseType: "json",
      withCredentials: false,
    };
    const http$ = this.http
      .post("https://stage.reporting.iopspro.com/Token", "grant_type=password&username=iops-application&password=d19476A9331BCA9C326E6F607651A240CD4DC0CDFD9BF5B29E43A3A4D31D0A6A!", httpOptions)
      .toPromise()
      .then((data: any) => {
        let httpGetHeaders = new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + data.body.access_token,
        });
        this.accessToken = data.body.access_token;
        let httpGetOptions = {
          headers: httpGetHeaders,
          observe: "response" as "response",
          reponseType: "json",
          withCredentials: false,
        };
        console.log(data);
        this.http
          .get("https://stage.reporting.iopspro.com/api/reportserver/v2/categories", httpGetOptions)
          .toPromise()
          .then((data: any) => {
            let categories: any = data.body;
            console.log(categories);
            this.categories = categories;
            let statement = "API.GetUserReportCategories @Username = '" + this.global.User.currentUser.Username + "'";
            this.dataService.SQLActionAsPromise(statement).then((data: any) => {
              console.log(data);

              this.userReportCategories = [];
              data.forEach((category) => {
                this.userReportCategories.push(category.Name);
              });

              this.http
                .get("https://stage.reporting.iopspro.com/api/reportserver/v2/reports", httpGetOptions)
                .toPromise()
                .then((data: any) => {
                  this.reports = data.body;

                  this.reports.forEach((report) => {
                    report.CategoryName = this.categories.find((category) => category.Id === report.CategoryId).Name;
                  });

                  this.reports = this.reports.filter((report) => {
                    return this.userReportCategories.indexOf(report.CategoryName) != -1;
                  });
                  this.customerReportsLoading = false;
                  this.gridViewReports = process(this.reports, { group: this.groups });
                });
            });
          });
      });
  }
}
