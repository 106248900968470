import { Component, Inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Global } from 'projects/shared-lib/src/lib/_constants/global.variables';
import { OktaAuth } from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';

@Component({
	selector: "app-home",
	templateUrl: "./home.component.html",
	styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
	isAuthenticated!: boolean;	
	public userName: string;

	constructor(private router: Router,	@Inject(OKTA_AUTH) public oktaAuth: OktaAuth) {}


	async ngOnInit() {

		this.isAuthenticated = await this.oktaAuth.isAuthenticated();
		if (!this.isAuthenticated) {
			this.router.navigate(["user/login"]);
		}

		if (Global?.User?.currentUser?.Dashboards != undefined) {
			this.router.navigate(["layout/dashboard/" + Global.User.currentUser.Dashboards.firstOrDefault().Id]);
		} else {
			this.router.navigate(["layout/dashboard-demo"]); // -- go to demo dashboard since user doesn't have any dashboards yet. --Kirk T. Sherer, February 4, 2020.
		}
	}				

}
