import { Component, Input, OnInit } from "@angular/core";
import { CurrencyPipe } from "@angular/common";
import { Global } from "../../_constants/global.variables";
import _ from "lodash";
import swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { FormControl, FormGroup } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import  moment from "moment";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { DataService } from "../../services/data.service";
import { SecurityService } from "../../services/security.service";
import { IGlobal } from "../../_models/global.model";
import { UtilityService } from "../../services/utility.service";
import { RecursiveDataNavigatorService } from "../../services/recursive-data-navigator.service";
import { CdkDragDrop, CdkDrag, moveItemInArray } from "@angular/cdk/drag-drop";
import { FileUploadListComponent } from "../file-upload-list/file-upload-list.component";
import { MatDialog } from "@angular/material/dialog";
import { AuthenticationService } from "../../services/authentication.service";
import { SignalRCoreService } from "../../services/signalr-core.service";
import { TextboxQuestion } from "../../_models/dynamic-fields/questions/question-textbox";
import { SliderYesNoQuestion } from "../../_models/dynamic-fields/questions/question-slider-yes-no";
import { NewPropertyQuestion } from "../../_models/dynamic-fields/questions/question-new-property";

export interface EditRecordObject {
	row?: any;
	type?: string;
	event?: any;
	indexReference?: any;
}
@Component({
	selector: "lib-recursive-data-navigator",
	templateUrl: "./recursive-data-navigator.component.html",
	styleUrls: ["./recursive-data-navigator.component.scss"]
})
export class RecursiveDataNavigatorComponent implements OnInit {
	@Input() public options: any;
	public global: IGlobal = Global;
	public searchText: string;
	public entity: any;
	public editModel: any;
	public showEditPane: boolean = false;
	public fileImageUploadEnabled: boolean = false;
	public jsonObject: any;
	public componentName: string = "RDN: ";
	private structure: any;
	public currentSet: any;
	public selectedRecursiveSet: any;
	public trElement: string;
	public numberOfColumns: number = 0;
	public mainStoredProcedure: string;
	public containingDivId: number;
	public recursiveSetColumnNamesForThisInstance: Array<string>;
	public currentStatus: string;
	public editFormOptions: any;
	public currentSetData: any;
	public currentSetDataCountOfRecords: number;
	public editFormTitle: string;
	public editFormSubTitle: string;
	public currentlyEditedRecord: any;
	public loadingEditForm: boolean = false;
	public allowAddRecord: boolean = true;
	public allowDownloadList: boolean = false;
	public allowCreateTagsForAsset: boolean = false;
	public visibleColumns: any;
	public currentChildColumnLink: string;
	public currentChildColumnLinkValue: any;
	public latestFullStoredProcedureString: string;
	public currentXPosition: number;
	public currentYPosition: number;
	public currentIndex: string;
	public allowDragAndDrop: boolean = false;
	public isLoadingList: boolean = false;
	public editQuestions: any;

	public min: Date = new Date(1917, 0, 1);
	public max: Date = new Date(2000, 11, 31);

	private currentStatus$: BehaviorSubject<string> = new BehaviorSubject("");
	public accessTokenChanged$: any;
	public currentUserAccessToken: string = Global.User.currentUser.AuthenticationTokenFromUserRecord;
	public currentUserIsAdmin: boolean = Global.User.isAdmin;
	private videoKeyURL: string = Global.Video.serverArchiveUrl;
	private accessToken: string = Global.User.currentUser.AuthenticationTokenFromUserRecord;

	private downloadSet: string = "";

	public iconmenu: string = "doc,docx,xls,xlsm,xlsx,msg,pdf,ppt,pptx,js,css,mp4,mp3,wav,zip,txt,json,config,cs,aspx,html,tif,tiff,vsd,chm,asp,sql,hl7,xml,linq,rdl,wma,msi,exe,reg,csv,webm";
	private gseAssetTypeIds: Array<number> = [86292, 86293, 86294, 86295, 86296, 86297, 86299, 86300];
	private fleetAssetTypeIds: Array<number> = [86313, 86314];

	public myForm: FormGroup = new FormGroup({
		birthDate: new FormControl(new Date(2000, 10, 10))
	});

	public fileImageData: any;
	public FileImageLibraryControlObject: any;
	public countOfFilesUploaded: number = 0;
	private fullDataCacheSubscription: any;
	public fullDataCacheExists: boolean = false;

	constructor(private dataService: DataService, public rdnService: RecursiveDataNavigatorService, private toastr: ToastrService, private securityService: SecurityService, private sanitizer: DomSanitizer, private utilityService: UtilityService, public dialog: MatDialog, private authenticationService: AuthenticationService, private signalR: SignalRCoreService) {
		this.searchForText = _.debounce(this.searchForText, 300);
	}

	ngOnInit() {
		this.rdnService.currentUser$.next(Global.User.currentUser);
		this.getCurrentUserAccessToken();
		Global.User.DebugMode && console.log(this.componentName + "initialize --> Global.User.currentUser.AuthenticationTokenFromUserRecord	= " + Global.User.currentUser.AuthenticationTokenFromUserRecord);

		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription = this.dataService.fullDataCacheExists$.subscribe((data: any) => {
				if (data === true) {
					this.fullDataCacheExists = true;
					this.initialize();
					this.fullDataCacheSubscription?.unsubscribe();
				}
			});
		} else {
			this.fullDataCacheExists = true;
			this.initialize();
		}

	}

	getCurrentUserAccessToken() {
		this.currentUserAccessToken = Global.User.currentUser.AuthenticationTokenFromUserRecord; //-- may have to change once everything is converted over to using Okta access tokens. --Kirk T. Sherer, December 20, 2024.
		this.currentUserIsAdmin = Global.User.isAdmin;
		console.log(this.componentName + "Global.User.isAdmin = " + Global.User.isAdmin);
	}

	initialize() {
		this.structure = this.rdnService.structure;
		this.currentStatus = "";

		this.currentStatus$.subscribe((currentStatus: string) => {
			if (currentStatus == "view-list" && this.currentIndex != null) {
				setTimeout(() => {
					let element = document.getElementById(this.currentIndex);
					element?.scrollIntoView({
						behavior: "smooth",
						block: "nearest",
						inline: "start"
					});
				}, 300);
			}
		});

		Global.User.DebugMode && console.log(this.componentName + "initialize --> options = %O", this.options);
		this.currentChildColumnLink = this.options.childColumnLink;
		this.currentChildColumnLinkValue = this.options.childColumnLinkValue;
		Global.User.DebugMode && console.log(this.componentName + "initialize --> currentChildColumnLink = ", this.currentChildColumnLink);
		Global.User.DebugMode && console.log(this.componentName + "initialize --> currentChildColumnLinkValue = ", this.currentChildColumnLinkValue);
		var requestedSetName = this.options.setName;
		Global.User.DebugMode && console.log(this.componentName + "initialize --> options.setName = " + requestedSetName);
		this.currentSet = this.structure.firstOrDefault((s: any) => {
			return s.setName == requestedSetName;
		});
		if (this.currentSet == null) {
			this.currentSet = this.rdnService.structure.firstOrDefault((s: any) => {
				return s.sets?.firstOrDefault((set: any) => {
					return set.Name == requestedSetName;
				});
			});
			this.currentSet.setName = requestedSetName;
			this.currentSet.tableDisplay.originalSQLStoredProcedure = this.currentSet.tableDisplay.originalSQLStoredProcedure == null ? this.currentSet.tableDisplay.sqlStoredProcedure : this.currentSet.tableDisplay.originalSQLStoredProcedure;
			this.currentSet.tableDisplay.sqlStoredProcedure =
				this.currentSet.tableDisplay.originalSQLStoredProcedure +
				this.currentSet.sets.firstOrDefault((set: any) => {
					return set.Name == requestedSetName;
				}).ParameterValue;
		}
		Global.User.DebugMode && console.log(this.componentName + "initialize --> Global.RDN.recursedSets = %O", Global.RDN.recursedSets);
		Global.User.DebugMode && console.log(this.componentName + "initialize --> currentSet = %O", this.currentSet);
		Global.User.DebugMode && console.log(this.componentName + "initialize --> currentSet.setName = " + this.currentSet.setName);
		this.signalR.LogActivity("Working with RDN Set: " + this.currentSet.setName);
		this.containingDivId = this.options.containingDivId;
		Global.User.DebugMode && console.log(this.componentName + "initialize --> containingDivId = " + this.options.containingDivId);
		Global.User.DebugMode && console.log(this.componentName + "initialize --> Global.User.currentUser.AuthenticationTokenFromUserRecord = " + Global.User.currentUser.AuthenticationTokenFromUserRecord);
		this.allowAddRecord = this.currentSet.editor && this.currentSet.editor.fields.length > 0 && this.currentSet.tableDisplay.allowModification && !this.currentSet.tableDisplay.disableAddRecord && ((this.options.containingDivId == null && !this.currentSet.tableDisplay.disableAddRecordOutsideRecursedSet) || (this.options.containingDivId != null && !this.currentSet.tableDisplay.disableAddRecordInsideRecursedSet));
		this.allowDownloadList = this.currentSet.editor && this.currentSet.editor.fields.length > 0 && this.currentSet.tableDisplay.allowDownloadList;
		this.allowCreateTagsForAsset = this.currentSet.editor && this.currentSet.editor.fields.length > 0 && this.currentSet.tableDisplay.allowCreateTagsForAsset;

		this.visibleColumns = this.currentSet.tableDisplay.displayColumns
			.where((col: any) => {
				var columnShouldBeVisible = true;
				Global.User.DebugMode && console.log(this.componentName + "col.name = " + col.name + ", col.hidden = " + col.hidden + ", col.role = " + col.role + ", currentUserIsAdmin = " + this.currentUserIsAdmin);
				if (col.role == "SystemAdministrator" && !this.currentUserIsAdmin) {
					columnShouldBeVisible = false;
				} else {
					if (col.hidden == true) {
						columnShouldBeVisible = false;
					}
				}
				return columnShouldBeVisible == true ? columnShouldBeVisible : false;
			})
			.toArray();

		this.allowDragAndDrop =
			this.currentSet.tableDisplay.displayColumns
				.where((col: any) => {
					return col.name == "Ordinal";
				})
				.firstOrDefault() != null;

		Global.User.DebugMode && console.log(this.componentName + "this.visibleColumns = %O", this.visibleColumns);
		Global.User.DebugMode && console.log(this.componentName + "this.allowDragAndDrop = %O", this.allowDragAndDrop);
		this.numberOfColumns = this.visibleColumns.length;
		var sqlStoredProcedure = null;

		if (this.currentSet?.tableDisplay?.dataCacheCollection != null) {
			this.formatDataCacheCollection();
		}
		else if (this.currentSet.tableDisplay.apiUrl != null) {
			var service = this;
			var url = service.currentSet.tableDisplay.apiUrl;

			if (service.currentChildColumnLink != null) {
				url += "?" + service.currentChildColumnLink + "=" + service.currentChildColumnLinkValue;
			}
			service.dataService.CallThingMethod(url).then((data:any) => {
				service.signalR.LogActivity("Execution of " + url + " was successful.");
				service.formatData(data, "JSON");
				service.currentStatus$.next(this.currentStatus);
				service.isLoadingList = false;
				return data;
			});
		}
		else if (this.currentSet.tableDisplay.sqlStoredProcedure != null) {
			this.buildStoredProcedureString(this.currentSet.tableDisplay.sqlStoredProcedure, this.options, this.currentSet.tableDisplay.expandedStoredProcedure ? null : this.currentSet.tableDisplay.displayColumns); //-- if the stored procedure is already expanding the relational fields, then don't send the display columns. They are already listed in the stored procedure.
		} else {
			if (this.currentSet.tableDisplay.tableName != null) {
				this.buildStoredProcedureString("API.RDN_GetTop50RecordsForSQLTable @tableName='" + this.currentSet.tableDisplay.tableName + "'", this.options, this.currentSet.tableDisplay.displayColumns);
			}
		}
		Global.User.DebugMode && console.log(this.componentName + "Global.RDN = %O", Global.RDN);
	}

	formatDataCacheCollection() {
		this.isLoadingList = true;
		var data = [];
		if (this.currentSet.tableDisplay.orderBy != null) {
			if (this.currentSet.tableDisplay.orderBy.sortOrder == "desc") {
				data = _.orderBy(this.currentSet.tableDisplay.dataCacheCollection, this.currentSet.tableDisplay.orderBy.column, "desc");
			} else {
				data = _.orderBy(this.currentSet.tableDisplay.dataCacheCollection, this.currentSet.tableDisplay.orderBy.column, "asc");
			}
		}
		else {
			data = this.currentSet.tableDisplay.dataCacheCollection;
		}

		if (data.length > 0) {
			data = data?.take(50).toArray();
			this.formatData(data, "data cache");
		}
		this.currentStatus$.next(this.currentStatus);
		this.rdnService.editingForm$.next(Global.RDN.editingForm);
		this.isLoadingList = false;
	}

	buildStoredProcedureString(sqlStoredProcedure: string, options: any, displayColumns?: any) {
		if (this.currentSet.tableDisplay.entityName == "Security.iOPSUser" || this.currentSet.tableDisplay.entityName == "Person" || this.currentSet.tableDisplay.entityName == "Camera") {
			this.getCurrentUserAccessToken();
			sqlStoredProcedure += " @accessToken='" + this.currentUserAccessToken + "'";
		} else if (sqlStoredProcedure == "API.RDN_SiteList_BySingleOrganization") {
			sqlStoredProcedure += " @Username='" + Global.User.currentUser.Username + "'";
		}

		if (displayColumns) {
			var fieldList = displayColumns
				.where((c: any) => {
					return c.type != "set" && c.name != "actions";
				})
				.toArray()
				.select((col: any) => {
					return col.name;
				})
				.toArray()
				.join(",");
			Global.User.DebugMode && console.log(this.componentName + "fieldList = " + fieldList);

			if (fieldList != "") {
				sqlStoredProcedure = sqlStoredProcedure + ", @fieldList='" + fieldList + "'";
			}
		} else {
			if (this.currentSet.tableDisplay.listFilter != null) {
				sqlStoredProcedure = sqlStoredProcedure + " " + this.currentSet.tableDisplay.listFilter.parameterForStoredProcedure + "=" + this.currentSet.tableDisplay.listFilter.trueParameter.value;
			}
		}

		if (options.childColumnLink && sqlStoredProcedure.indexOf("RDN_GetTop50RecordsForSQLTable") > 0) {
			sqlStoredProcedure = sqlStoredProcedure + ", @childColumnLink='" + options.childColumnLink + "', @childColumnLinkValue='" + options.childColumnLinkValue + "'";
		} else {
			if (options.childColumnLink) {
				//-- we're using a stored procedure that is expecting the 'childColumnLink' as the actual field name for the childColumnLinkValue. --Kirk T. Sherer, May 12, 2021.
				if (sqlStoredProcedure.indexOf("@") > 0) {
					//-- if we already have at least one @ parameter, add a comma since we're adding another one. --Kirk T. Sherer, June 2, 2021.
					sqlStoredProcedure += ", ";
				}
				if (options.childColumnLink.indexOf("Id") == -1) {
					//-- we're not dealing with an Id field for the column link, so we'll have to assume the field needs quotes around the value. --Kirk T. Sherer, June 11, 2021.
					sqlStoredProcedure += " @" + options.childColumnLink + "='" + options.childColumnLinkValue + "'";
				} else {
					sqlStoredProcedure += " @" + options.childColumnLink + "=" + options.childColumnLinkValue;
				}
			} else {
				if (options.parameters) {
					var numberOfParameters = options.parameters.length;
					var countOfParameters = 0;
					options.parameters.forEach((parameter: any) => {
						Global.User.DebugMode && console.log(this.componentName + "parameter.Value = " + parameter.Value);
						var isDate = moment.isDate(parameter.Value);
						if (isDate) {
							parameter.Value = this.utilityService.ConvertDateTimeToUTCDateTimeAndFormatForSQLParameter(parameter.Value);
							Global.User.DebugMode && console.log(this.componentName + "parameter.Value = " + parameter.Value);
						}
						if (parameter.Name.indexOf("Id") == -1) {
							//-- we're not dealing with an Id field for the column link, so we'll have to assume the field needs quotes around the value. --Kirk T. Sherer, November 4, 2022.
							sqlStoredProcedure += " " + parameter.Name + "='" + parameter.Value + "'";
						} else {
							sqlStoredProcedure += " " + parameter.Name + "=" + parameter.Value;
						}
						countOfParameters++;
						if (countOfParameters < numberOfParameters) {
							sqlStoredProcedure += ", ";
						}
						Global.User.DebugMode && console.log(this.componentName + "parameter = %O", parameter);
					});
				}
			}
		}

		if (!this.mainStoredProcedure) {
			this.mainStoredProcedure = sqlStoredProcedure; //-- saving this as the baseline stored procedure string for search text to go back to original list. --Kirk T. Sherer, May 12, 2021.
		}

		this.executeStoredProcedure(sqlStoredProcedure);
	}

	executeStoredProcedure(sqlStoredProcedure: string) {
		var service = this;
		Global.User.DebugMode && console.log(this.componentName + "<--- executeStoredProcedure invoked --->");
		Global.User.DebugMode && console.log(service.componentName + "executeStoredProcedure --> sqlStoredProcedure = " + sqlStoredProcedure);

		this.latestFullStoredProcedureString = sqlStoredProcedure;
		try {
			this.signalR.LogActivity("Executed RDN Stored Procedure: " + sqlStoredProcedure);

			this.isLoadingList = true;
			this.dataService.SQLActionAsPromise(sqlStoredProcedure).then((data: any) => {
				this.signalR.LogActivity("Execution of RDN Stored Procedure was successful.");
				this.formatData(data, "stored procedure");
				service.currentStatus$.next(service.currentStatus);
				this.isLoadingList = false;
				return data;
			});
		} catch (error) {
			swal.fire("ERROR: " + error.message);
			this.isLoadingList = false;
		}
	}

	formatData(data: any, formatType: string) {
		var service = this;
		Global.User.DebugMode && console.log(service.componentName + formatType + " result set = %O", data);

		service.currentSetData = data;
		service.currentSetDataCountOfRecords = data.length;
		Global.User.DebugMode && console.log(service.componentName + "service.currentSetDataCountOfRecords = " + service.currentSetDataCountOfRecords);

		if (data.length > 0) {

			this.recursiveSetColumnNamesForThisInstance = service.currentSet.tableDisplay.displayColumns
			.where((column: any) => {
				return column.type == "set";
			})
			.select((column: any) => {
				return column.name;
			})
			.toArray();
			Global.User.DebugMode && console.log(this.componentName + "this.recursiveSetColumnNamesForThisInstance = %O", this.recursiveSetColumnNamesForThisInstance);

			if (service.currentSet.tableDisplay.orderBy != null) {
				if (service.currentSet.tableDisplay.orderBy.sortOrder == "desc") {
					data = _.orderBy(data, service.currentSet.tableDisplay.orderBy.column, "desc");
				} else {
					data = _.orderBy(data, service.currentSet.tableDisplay.orderBy.column, "asc");
				}
			}

			var downloadFieldsExist = false;

			data.forEach((row: any) => {
				var downloadRowString = "";
				var countOfDownloadFields = 0;
				//Global.User.DebugMode && console.log(service.componentName + "row = %O", row);
				var originalRow = row;
				//Global.User.DebugMode && console.log(service.componentName + "originalRow = %O", originalRow);
				service.currentSet.tableDisplay.displayColumns.forEach((column: any) => {
					//Current State
					//row[column.name] = a primitive value;
					//
					//Reformat to:
					if (column.download) {
						if (countOfDownloadFields > 0) {
							downloadRowString += "~~!!" + column.name + "^" + row[column.name];
						} else {
							downloadRowString += column.name + "^" + row[column.name];
						}
						countOfDownloadFields++;
						downloadFieldsExist = true;
					}

					if (column.type == "text") {
						var columnValue = "" + row[column.name];

						var plusSignDelimiter = "+++";
						if (columnValue.indexOf(plusSignDelimiter) !== -1) {
							if (columnValue.indexOf(plusSignDelimiter) <= 1) {
								columnValue = columnValue.replace("+++", "");
								if (columnValue.indexOf(plusSignDelimiter) !== -1) {
									columnValue = columnValue.split("+++").join("\r\n");
								}
							} else {
								columnValue = columnValue.split("+++").join("\r\n");
							}

							Global.User.DebugMode && console.log(this.componentName + "columnValue.slice(columnValue.length - 7) = " + columnValue.slice(columnValue.length - 7));

							if (columnValue.slice(columnValue.length - 7) == "\r\nAND\r\n") {
								columnValue = columnValue.substring(0, columnValue.length - 7);
							}
						} else {
							columnValue = row[column.name];
						}

						row[column.name] = {
							value: columnValue,
							rdnRecursionSet: null
						};
					} else {
						row[column.name] = {
							value: row[column.name],
							rdnRecursionSet: null
						};
					}

					if (column.type == "set") {
						var currentContainingDivId = ++Global.RDN.maxId;

						if (column.parentColumnLink != null) {
							var recursiveSet = {
								containingDivId: currentContainingDivId,
								parentColumnLink: column.parentColumnLink,
								childColumnLink: column.childColumnLink,
								childColumnLinkValue: originalRow[column.parentColumnLink]?.value != null ? originalRow[column.parentColumnLink].value : originalRow[column.parentColumnLink], //--original row contains the row the way it came from SQL Server.
								setName: column.setName
							};

							row[column.name].rdnRecursionSet = recursiveSet;
							Global.RDN.recursedSets.push(recursiveSet);
						} else {
							//-- sending parameters to the stored procedure rather than a child column link since the table represented in the stored procedure really isn't a child table of the table where the
							//-- button-click originated. --Kirk T. Sherer, November 4, 2022.
							var parameterListWithValues = [];
							column.parameters.forEach((parameter: any) => {
								var parameterWithValue = {
									Name: parameter.name,
									Value: originalRow[parameter.value]?.value != null ? originalRow[parameter.value].value : originalRow[parameter.value]
								};
								parameterListWithValues.push(parameterWithValue);
							});
							var storedProcedureRecursiveSet = {
								containingDivId: currentContainingDivId,
								parameters: parameterListWithValues,
								setName: column.setName
							};

							row[column.name].rdnRecursionSet = storedProcedureRecursiveSet;
							Global.RDN.recursedSets.push(storedProcedureRecursiveSet);
						}
					}

					if (column.type == "file-image") {
						var imageKey = originalRow["ImageKey"]?.value != null ? originalRow["ImageKey"]?.value : originalRow["ImageKey"];
						if (imageKey != null) {
							var filename = originalRow["Filename"]?.value != null ? originalRow["Filename"]?.value : originalRow["Filename"];
							var fileExtension = filename.split(".")[1];
							var iconFile = null;
							if (this.iconmenu.indexOf(fileExtension.toLowerCase()) > -1) {
								//This is an icon file display. Assign the right one.
								iconFile = global.imagesUrl + "assets/img/FileUpload/icon-" + fileExtension + ".png";
							}
							var isVideoServerImage = originalRow["IsVideoServerImage"]?.value != null ? originalRow["IsVideoServerImage"]?.value : originalRow["IsVideoServerImage"];
							var videoServerFilename = originalRow["VideoServerFilename"]?.value != null ? originalRow["VideoServerFilename"]?.value : originalRow["VideoServerFilename"];
							var thumbnailUrl = null;
							var thumbnailBase64 = originalRow["ThumbnailBase64"]?.value != null ? originalRow["ThumbnailBase64"]?.value : originalRow["ThumbnailBase64"];
							var thumbnailImage = thumbnailBase64 ? this.sanitizer.bypassSecurityTrustResourceUrl("data:image/png;base64," + thumbnailBase64) : null;
							var viewUrl = null;
							if (isVideoServerImage && isVideoServerImage == true) {
								viewUrl = this.videoKeyURL + videoServerFilename + "?accessToken=" + encodeURIComponent(this.accessToken);
								thumbnailUrl = null;
							} else {
								thumbnailUrl = this.dataService.imageKeyURL + imageKey;
								viewUrl = thumbnailUrl;
							}

							var fileImage = {
								name: filename,
								iconFile: iconFile,
								thumbnailImage: thumbnailImage,
								thumbnailUrl: thumbnailUrl,
								viewUrl: viewUrl
							};

							row[column.name].fileImage = fileImage;
						}
					}
				});
				if (downloadFieldsExist) {
					downloadRowString += "\r\n";
					service.downloadSet += downloadRowString;
				}
			});
		}
		Global.User.DebugMode && console.log(service.componentName + "formatData --> this.currentSet = %O", service.currentSet);
		Global.User.DebugMode && console.log(service.componentName + "formatData --> this.currentSetData = %O", service.currentSetData);
		console.log("Global.User = %O", Global.User);
		var global: IGlobal = Global;
		if (!Global.User.isAdmin && service.currentSet.setName == "Sites") {
			var listOfValidSites = service.currentSetData
				.where((csd: any) => {
					return (
						Global.User.PermittedSites.firstOrDefault((site: any) => {
							return site.Id == csd.Id.value;
						}) != null
					);
				})
				.where((site: any) => {
					return Global.User.Role[site.Name.value].Administrator == true;
				})
				.toArray();
			console.log("listOfValidSites = %O", listOfValidSites);
			service.currentSetData = listOfValidSites;
		}
		Global.User.DebugMode && console.log(service.componentName + "formatData --> this.currentSet.setName = " + this.currentSet.setName);
		service.currentStatus = "view-list";
	}

	goToSet(rowFieldData: any, event: Event) {
		event.stopPropagation();
		event.preventDefault();
		if (this.selectedRecursiveSet == rowFieldData.rdnRecursionSet) {
			//-- user is trying to collapse the recursive set.  Just unset everything so the list goes back to normal. --Kirk T. Sherer, June 16, 2021.
			this.selectedRecursiveSet = null;
			Global.User.DebugMode && console.log(this.componentName + "this.selectedRecursiveSet set back to null since user clicked on same button to collapse it.");
		} else {
			Global.User.DebugMode && console.log(this.componentName + "<--- goToSet invoked --->");
			Global.User.DebugMode && console.log(this.componentName + "rowFieldData = %O", rowFieldData);
			var trElement = "rdnRecursionSetContainingDiv_Id" + rowFieldData.rdnRecursionSet.containingDivId;
			Global.User.DebugMode && console.log(this.componentName + "trElement = " + trElement);
			rowFieldData.rdnRecursionSet.trElement = trElement;
			this.selectedRecursiveSet = rowFieldData.rdnRecursionSet;
			Global.User.DebugMode && console.log(this.componentName + "this.selectedRecursiveSet = %O", this.selectedRecursiveSet);
		}
	}

	sendChangePasswordToken(username: any, event: Event) {
		event.stopPropagation();
		event.preventDefault();
		Global.User.DebugMode && console.log(this.componentName + "<--- sendChangePasswordToken invoked --->");
		Global.User.DebugMode && console.log(this.componentName + "username = " + username);

		if (username) {
			//-- log that someone else is sending the password token rather than the actual person. --Kirk T. Sherer, August 12, 2021.
			Global.User.DebugMode && console.log(this.componentName + "attempting to send reset password token to " + username + "'s email account...");
			this.dataService.RequestToResetPassword(username, "" + Global.User.currentUser.Id).then((data: any) => {
				this.signalR.LogActivity("Sent change password token email to " + username + ".");
				Global.User.DebugMode && console.log(this.componentName + "sendPasswordToken: data.body = %O", data.body);
				var email = data.body.first();
				this.sendAlertToUser(email);
			});
		}
	}

	sendAlertToUser(email: string) {
		if (email == undefined) {
			swal.fire({
				position: "center",
				html: "A Change Password email has been sent to the user's email address.<br />This password change token can be used only once and it will expire in one (1) hour."
			});
		} else {
			swal.fire({
				position: "center",
				html: "A Change Password email has been sent to <strong>" + email + "</strong>.<br />This password change token can be used only once and it will expire in one (1) hour."
			});
		}
	}

	logInAs(username: any, event: Event) {
		event.stopPropagation();
		event.preventDefault();
		Global.User.DebugMode && console.log(this.componentName + "<--- logInAs invoked --->");
		Global.User.DebugMode && console.log(this.componentName + "username = " + username);

		//-- the following stored procedure will set the 'AdminstratorCurrentlyLoggedInAsUserId' field on the user's account so we can audit what the administrator is doing against their own UserId while logged in as the other user. --Kirk T. Sherer, September 1, 2022.
		var sql = "Security.Admin_LogInAs_Username @Username='" + username + "', @UserId=" + Global.User.currentUser.Id;
		this.dataService.SQLActionAsPromise(sql).then((data: any) => {
			Global.User.DebugMode && console.log(this.componentName + "sql: " + sql + ", data: %O", data);
			this.signalR.LogActivity("Executed RDN stored procedure: " + sql + ".");

			swal.fire({
				position: "center",
				html: "You are now logging in as <strong>'" + username + "'</strong>.  You will have to log out of this user's account and back into your own account when finished."
			}).then(() => {
				var currentAdminUser = Global.User.currentUser;
				this.signalR.LogActivity("'" + currentAdminUser.Username + "' is logging in as '" + username + "'.");
				localStorage.removeItem("currentUser");
				Global.User.DebugMode = false;
				Global.User.Menu = null;
				Global.User.PermittedSites = null;
				Global.User.Privilege = null;
				Global.User.Role = null;
				Global.User.currentUser = null;
				Global.User.isAdmin = false;
				Global.User.isOrgAdmin = false;
				Global.User.isSiteAdmin = false;
				Global.User.isLoggedIn = false;
				Global.User.needToBuildMenu = true;
				Global.SignalR.ListOfTagNamePrefixes = null;
				Global.SignalR.ListOfAdditionalSignalRGroups = null;
				this.signalR.stopHub();
				this.securityService.logInAsDifferentUser(data.first().AdminLogInAsToken);
			});
		});
	}

	searchForText(event: any) {
		Global.User.DebugMode && console.log(this.componentName + "event = %O", event);
		this.searchText = event;

		if (this.currentSet.tableDisplay.apiUrl != null) {
			//-- if this is using an API, set up the search query for the API:
			var service = this;
			var url = service.currentSet.tableDisplay.apiUrl;

			if (service.currentChildColumnLink != null) {
				url += "?" + service.currentChildColumnLink + "=" + service.currentChildColumnLinkValue;
			}

			url += "?withProperties=no&assetName=%2A&search=" + this.searchText;

			service.dataService.CallThingMethod(url).then((data:any) => {
				service.signalR.LogActivity("Execution of " + url + " was successful.");
				service.formatData(data, "JSON");
				service.currentStatus$.next(this.currentStatus);
				service.isLoadingList = false;
				return data;
			});
		}
		else {
			//-- this set is using a standard stored procedure call.
			var sqlStoredProcedure = this.mainStoredProcedure;

			var parsedSearchText = this.searchText.trim().split("'").join("''"); //--this.searchText.replace(/\s/g, "")
			if (this.mainStoredProcedure.indexOf(",") > 0 || this.mainStoredProcedure.indexOf("@") > 0) {
				sqlStoredProcedure = sqlStoredProcedure + ", @SearchText='" + parsedSearchText + "'"; //-- search text parameter must have comma in front since there are other parameters in the sql stored procedure string. --Kirk T. Sherer, May 12, 2021.
			} else {
				sqlStoredProcedure = sqlStoredProcedure + " @SearchText='" + parsedSearchText + "'"; //-- no comma needed after main stored procedure since @SearchText is the only parameter in the list. --Kirk T. Sherer, May 12, 2021.
			}

			Global.User.DebugMode && console.log(this.componentName + "sqlStoredProcedure = " + sqlStoredProcedure);
			if (this.searchText == "") {
				sqlStoredProcedure = this.mainStoredProcedure;
			}

			this.executeStoredProcedure(sqlStoredProcedure);
		}
	}

	openInNewWindow(url: string, event: Event) {
		this.signalR.LogActivity("Opening " + url + " in another window...");
		event.stopPropagation();
		event.preventDefault();

		this.currentUserAccessToken = Global.User.currentUser.AuthenticationTokenFromUserRecord; //-- this MUST be changed to the Okta AuthenticationToken as soon as Grant has updated the video server to decode / recognize Okta Access Tokens. --Kirk T. Sherer, December 19, 2024.

		window.open(url + "&accessToken=" + encodeURIComponent(this.currentUserAccessToken), "_blank").focus();
	}

	CancelEdit() {
		this.signalR.LogActivity("Cancelled editing of record.");

		this.entity = null;
		this.editModel = null;
		this.showEditPane = false;
		this.options.editor.standaloneShow = false;
	}

	updateTags() {
		Global.User.DebugMode && console.log(this.componentName + "updateTags function was invoked... ");
		var service = this;
		var swalWithBootstrapButtons = swal.mixin({
			customClass: {
				confirmButton: "btn btn-danger",
				cancelButton: "btn btn-success"
			},
			buttonsStyling: false
		});
		swalWithBootstrapButtons
			.fire({
				title: "Are you sure?",
				html: "This will add or update ALL of the tags that are not matching in the list. You won't be able to revert this once the operation is complete.",
				showCancelButton: true,
				confirmButtonText: "Update Tags",
				cancelButtonText: "Cancel",
				reverseButtons: false
			})
			.then((result) => {
				if (result.value) {
					var sql = "API.RDN_GetPLCProgramRevisionPossibleTags @UpdateTags=1, @UserId=" + Global.User.currentUser.Id + ",";
					if (this.options.parameters) {
						var numberOfParameters = this.options.parameters.length;
						var countOfParameters = 0;
						this.options.parameters.forEach((parameter: any) => {
							Global.User.DebugMode && console.log(this.componentName + "parameter.Value = " + parameter.Value);
							var isDate = moment.isDate(parameter.Value);
							if (isDate) {
								parameter.Value = this.utilityService.ConvertDateTimeToUTCDateTimeAndFormatForSQLParameter(parameter.Value);
								Global.User.DebugMode && console.log(this.componentName + "parameter.Value = " + parameter.Value);
							}
							if (parameter.Name.indexOf("Id") == -1) {
								//-- we're not dealing with an Id field for the column link, so we'll have to assume the field needs quotes around the value. --Kirk T. Sherer, November 4, 2022.
								sql += " " + parameter.Name + "='" + parameter.Value + "'";
							} else {
								sql += " " + parameter.Name + "=" + parameter.Value;
							}
							countOfParameters++;
							if (countOfParameters < numberOfParameters) {
								sql += ", ";
							}
							Global.User.DebugMode && console.log(this.componentName + "parameter = %O", parameter);
						});

						this.isLoadingList = true;
						this.executeStoredProcedure(this.mainStoredProcedure); //-- go back to previous data.

						this.dataService.SQLActionAsPromise(sql).then((data: any) => {
							this.isLoadingList = false;
							if (data.length > 0) {
								var countOfNewTags = data.first().CountOfNewTags;
								var countOfUpdatedTags = data.first().CountOfUpdatedTags;
								this.utilityService.showToastMessageShared({
									type: "success",
									title: "Updated Asset",
									message: "New Tags inserted: " + countOfNewTags + ", Updated Tags: " + countOfUpdatedTags + " from PLC Program Revision Tag Simple Name list."
								});
							} else {
								this.utilityService.showToastMessageShared({
									type: "error",
									message: "Tags were not inserted or updated from PLC Program Revision Tag Simple Name list."
								});
							}
						});
					}
				} else if (result.dismiss === swal.DismissReason.cancel) {
					this.signalR.LogActivity("Cancelled add/update of tags for a specific asset.");
					service.utilityService.showToastMessageShared({
						type: "info",
						message: this.currentSet.tableDisplay.entityDisplayName + " tags have NOT been modified for the asset.",
						title: service.currentSet.tableDisplay.label
					});
				}
			});

	}

	downloadList() {
		Global.User.DebugMode && console.log(this.componentName + "download list was invoked... downloadSet = %O", this.downloadSet);

		if (this.downloadSet != "") {
			Global.User.DebugMode && console.log(this.componentName + "running webAPI for downloading the list...");
			this.dataService.downloadDatasetAsExcelSpreadsheet(this.downloadSet).then((data: any) => {
				Global.User.DebugMode && console.log(this.componentName + "dataService.downloadDatasetAsExcelSpreadsheet data: %O", data);
				//Global.User.DebugMode && console.log(this.componentName + "dataService.downloadDatasetAsExcelSpreadsheet data.image: %O", data.image);
				const element = document.createElement("a");
				//element.type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
				element.href = URL.createObjectURL(data);
				element.download = "SpreadsheetDownload.xlsx";
				document.body.appendChild(element);
				element.click();
				//window.open(fileURL);

				// var url: any = this.sanitizer.bypassSecurityTrustResourceUrl("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" + data);

				// var intHSize = window.screen.availWidth * 0.7;
				// var intVSize = window.screen.availHeight * 0.7;
				// var intTop = (window.screen.availHeight - intVSize) / 2;
				// var intLeft = (window.screen.availWidth - intHSize) / 2;
				// var sngWindow_Size_Percentage = 0.85;

				// var strFeatures = "";
				// intHSize = window.screen.availWidth * sngWindow_Size_Percentage * 0.6;
				// intVSize = window.screen.availHeight * sngWindow_Size_Percentage;
				// intTop = (window.screen.availHeight - intVSize) / 2;
				// intLeft = (window.screen.availWidth - intHSize) / 2;
				// strFeatures = "top=" + intTop + ",left=" + intLeft + ",height=" + intVSize + ",width=" + intHSize + ", scrollbars=yes, resizable=yes, location=no";
				// console.log("data returned from dataService.downloadDatasetAsExcelSpreadsheet. url =%O", url);

				// var SRWindow = window.open(url, "SRWindow", strFeatures);
			});
		}
	}

	reloadRDNQuestions(event: any) {
		if (this.editQuestions == undefined) {
			this.editQuestions = event;
		}
		console.log("this.editQuestions = %O", this.editQuestions);
		this.currentSet.editor.fields = event;
	}

	editRecord(editRecordObject: EditRecordObject) {
		Global.User.DebugMode && console.log(this.componentName + "editRecord for " + this.currentSet.tableDisplay.entityDisplayName + " invoked... %O", editRecordObject);

		let row = editRecordObject.row;
		let type = editRecordObject.type;
		let event = editRecordObject.event;
		let index = editRecordObject.indexReference;
		if (event != null) {
			var cX = event.clientX;
			var screenX = event.screenX;
			var cY = event.clientY;
			var screenY = event.screenY;
			var coords1 = "client - X: " + cX + ", Y coords: " + cY;
			var coords2 = "screen - X: " + screenX + ", Y coords: " + screenY;

			this.currentXPosition = screenX;
			this.currentYPosition = screenY;
		}

		if (index != null) {
			this.currentIndex = this.currentSet.tableDisplay.entityName + "_" + index;
		}

		Global.User.DebugMode && console.log(this.componentName + "editingRecord: currentChildColumnLink = " + this.currentChildColumnLink + ", currentChildColumnLinkValue = " + this.currentChildColumnLinkValue);
		var service = this;
		this.loadingEditForm = true;
		Global.User.DebugMode && console.log(this.componentName + "edit record invoked. type = " + type + ", row = %O", row);
		Global.RDN.editingForm = true;
		this.rdnService.editingForm$.next(Global.RDN.editingForm);
		this.editFormTitle = type == "add" ? "Adding New " + this.currentSet.tableDisplay.entityDisplayName + " Record" : "Editing " + this.currentSet.tableDisplay.entityDisplayName + " Record";
		Global.User.DebugMode && console.log(this.componentName + " " + this.editFormTitle);
		this.signalR.LogActivity(this.editFormTitle);

		this.editFormOptions = {
			submitButtonText: type == "add" ? "Add New Record" : "Save Changes",
			saveValuesPerField: this.currentSet.editor.saveApiUrl != undefined ? true : false,
			saveStoredProcedureName: this.currentSet.editor.saveStoredProcedureName ?? null,
			saveApiUrl: this.currentSet.editor.saveApiUrl != undefined ? this.currentSet.editor.saveApiUrl + "?thingId=" + row["id"]?.value + "&" : null,
			deleteApiUrl: this.currentSet.editor.deleteApiUrl != undefined ? this.currentSet.editor.deleteApiUrl + "?thingId=" + row["id"]?.value + "&" : null,
			allowAddNewQuestion: this.currentSet.editor.allowAddNewQuestion ?? false,
			cancelButtonText: "Cancel"
		};


		console.log("this.editFormOptions = %O", this.editFormOptions);

		if (type == "edit") {
			var fieldList = this.currentSet.editor.fields
				.select((field: any) => {
					return field.key.replace("@", "");
				})
				.toArray()
				.join(",");

			this.currentlyEditedRecord = row;

			if (this.currentSet.editor.editApiUrl != null) {
				this.currentSet.editor.getEditValuesFromList = true;
				this.dataService.CallThingMethod(this.currentSet.editor.editApiUrl + "?Id=" + row["id"].value).then((data:any) => {
					console.log("editable fields = %O", data);
					fieldList = data.select((item: any) => { return item.key }).toArray().join(",");
					this.currentSet.editor.properties = data;
					this.buildEditFormFields(row);
				});
			}
			else {
				var editStoredProcedureName = "";

				if (this.currentSet.editor.editStoredProcedureName != null) {
					editStoredProcedureName = this.currentSet.editor.editStoredProcedureName + " @entityId=" + row["Id"].value + ", @UserId=" + Global.User.currentUser.Id; //-- this is if the developer wants to be more creative in editing the record (i.e. by joining other fields that might not be part of a single record edit.)
					if (this.currentSet.editor.parentKeyField != null) {
						editStoredProcedureName += ", @parentKeyField=" + row[this.currentSet.editor.parentKeyField].value;
					}
				} else {
					if (this.currentSet.editor.getEditValuesFromList) {

					} else {
						editStoredProcedureName = "API.RDN_EditSQLTableRecord @entityId=" + row["Id"].value + ", @entityName='" + this.currentSet.tableDisplay.entityName + "', @fieldList='" + fieldList + "', @UserId=" + Global.User.currentUser.Id; //-- this is the default stored procedure for editing the current record.
						Global.User.DebugMode && console.log(this.componentName + "fieldList = " + fieldList);
					}
				}
				Global.User.DebugMode && console.log(this.componentName + "editStoredProcedureName = " + editStoredProcedureName);
				this.buildEditFormFields(row, editStoredProcedureName);

			}


			Global.User.DebugMode && console.log(this.componentName + "currentSet: %O", this.currentSet);
			this.currentStatus = "edit-record";
			this.currentStatus$.next(this.currentStatus);
		} else {
			//-- if we made it here, we're adding a new record.  That means any childColumnLink field values (if available) are for the Parent Key Field, not the ID number of the record we're adding. --Kirk T. Sherer, March 20, 2023
			this.currentlyEditedRecord = null;
			this.currentSet.childColumnLink = this.currentChildColumnLink;
			this.currentSet.childColumnLinkValue = this.currentChildColumnLinkValue;
			this.currentSet.editor.fields.forEach((field: any) => {
				field.value = null;
				if (field.type == "parent-key-field") {
					if (field.key == this.currentChildColumnLink) {
						field.value = this.currentChildColumnLinkValue;
					}
				}
				if (field.key == "Id") {
					field.visible = false; //-- this is an insert of a record, so there's no need to display the Id field. --Kirk T. Sherer, July 7, 2021.
				}
				if (field.buttonList && field.buttonList.parameters != null) {
					Global.User.DebugMode && console.log(this.componentName + "field.buttonList.parameters = %O", field.buttonList.parameters);
					field.buttonList.parameters.forEach((parameter: any) => {
						if (parameter.listValue != null) {
							Global.User.DebugMode && console.log(this.componentName + "parameter.listValue = " + parameter.listValue);
							Global.User.DebugMode && console.log(this.componentName + "this.currentSetData = %O", this.currentSetData);
							this.currentSetData.forEach((row: any) => {
								Global.User.DebugMode && console.log(this.componentName + "row = %O", row);
								Global.User.DebugMode && console.log(this.componentName + "row[parameter.listLookupField] = %O", row[parameter.listLookupField]);
								if (row[parameter.listLookupField].value == parameter.listLookupValue) {
									parameter.value = row[parameter.listValue].value;
								}
							});
							Global.User.DebugMode && console.log(this.componentName + "parameter.value = " + parameter.value);
						}
					});
				}
			});

			Global.User.DebugMode && console.log(this.componentName + "currentSet: %O", this.currentSet);
			this.loadingEditForm = false;
			this.currentStatus = "edit-record";
			this.currentStatus$.next(this.currentStatus);
		}
	}

	buildEditFormFields(row: any, editStoredProcedureName?: string) {
		//-- build field list for editing.
		var service = this;
		if (!service.currentSet.editor.getEditValuesFromList) {
			service.signalR.LogActivity("Executing RDN Edit stored procedure: " + editStoredProcedureName);
			service.dataService.SQLActionAsPromise(editStoredProcedureName).then((data: any) => {
				Global.User.DebugMode && console.log(service.componentName + " " + service.currentSet.editor.editStoredProcedureName + " data = %O", data);
				service.currentSet.editor.fields.forEach((field: any) => {
					data.forEach((row: any) => {
						//Global.User.DebugMode && console.log(this.componentName + "field.key: " + field.key + ", row['" + field.key.replace("@", "") + "'] = %O", row[field.key.replace("@", "")]);
						if (field.type == "date") {
							if (row[field.key.replace("@", "")] == null) {
								field.value = null;
							} else {
								field.value = new Date(row[field.key.replace("@", "")]);
							}
						} else {
							field.value = row[field.key.replace("@", "")];
						}
					});
					if (field.buttonList && field.buttonList.parameters != null) {
						Global.User.DebugMode && console.log(service.componentName + "field.buttonList.parameters = %O", field.buttonList.parameters);
						field.buttonList.parameters.forEach((parameter: any) => {
							if (parameter.listValue != null) {
								Global.User.DebugMode && console.log(service.componentName + "parameter.listValue = " + parameter.listValue);
								Global.User.DebugMode && console.log(service.componentName + "this.currentSetData = %O", service.currentSetData);
								service.currentSetData.forEach((row: any) => {
									Global.User.DebugMode && console.log(service.componentName + "row = %O", row);
									Global.User.DebugMode && console.log(service.componentName + "row[parameter.listLookupField] = %O", row[parameter.listLookupField]);
									if (row[parameter.listLookupField].value == parameter.listLookupValue) {
										parameter.value = row[parameter.listValue].value;
									}
								});
								Global.User.DebugMode && console.log(service.componentName + "parameter.value = " + parameter.value);
							}
						});
					}
					else if (field.listOfValues != null) {
						field.listOfValues.forEach((listItem: any) => {
							if (listItem.value != null) {
								Global.User.DebugMode && console.log(service.componentName + "listItem.Value = " + listItem.value);
								Global.User.DebugMode && console.log(service.componentName + "this.currentSetData = %O", service.currentSetData);
								service.currentSetData.forEach((row: any) => {
									Global.User.DebugMode && console.log(service.componentName + "row = %O", row);
									Global.User.DebugMode && console.log(service.componentName + "row[listItem.Id] = %O", row[listItem.Id]);
									if (row[field.key].value == listItem.value) {
										listItem.value = row[field.key].value;
									}
								});
								Global.User.DebugMode && console.log(service.componentName + "listItem.Value = " + listItem.value);
							}
						});
					}
				}	);
				Global.User.DebugMode && console.log(service.componentName + "this.currentSet.editor.fields = %O", service.currentSet.editor.fields);
				service.loadingEditForm = false;
			});
		} else {
			// -- using the current 'row' for the record we are editing.  This prevents us from having to run another stored procedure to retrieve the same information that is already in the
			// -- list being displayed.  The user clicked on the pencil to edit it, and we're just simply using that row to fill in the fields.  This would work for any set of records where
			// -- the list contains all of the editable fields for the edit form. --Kirk T. Sherer, March 6, 2023.

			if (service.currentSet.editor.properties != null) {
				console.log("this.currentSet.editor.properties = %O", service.currentSet.editor.properties);
				var visibleProperties = service.currentSet.editor.properties.where((property:any) => {
					var isHidden = property.isHidden;
					return property.isHidden == false
				}).toArray();

				visibleProperties.forEach((property:any) => {
					var definedPropertyInRDN = service.currentSet.editor.fields.firstOrDefault((field:any) => { return field.key == property.name });
					if (!definedPropertyInRDN) {
						var propertyNameDisplay = property.name.replace(/([A-Z])/g, ' $1').trim();
						if (property.type == "Boolean") {
							var newBooleanField = new SliderYesNoQuestion({
								key: property.name,
								label: propertyNameDisplay,
								title: "Is this " + propertyNameDisplay + " property true or false?",
								order: service.currentSet.editor.properties.length + 1,
								value: property.value,
								isDynamicProperty: true
							});
							service.currentSet.editor.fields.push(newBooleanField); //-- adding a new field for the property that is not defined in the RDN service.
						}
						else {
							var newTextField = new TextboxQuestion({
								key: property.name,
								label: propertyNameDisplay,
								title: "Please fill in the appropriate value for this " + propertyNameDisplay + " field.",
								order: service.currentSet.editor.properties.length + 1,
								type: "text",
								required: false,
								value: property.value,
								isDynamicProperty: true
							});
							service.currentSet.editor.fields.push(newTextField); //-- adding a new field for the property that is not defined in the RDN service.
						}
					}
					else {
						var field = definedPropertyInRDN;
						//-- we're receiving properties from the Thing webAPI.  If we don't have a field defined that matches on the property, we'll have to build a new question for the property coming from the API.
						service.formatDefinedField(field, property);
					}
				});

				var fieldsDefinedInRDNButNotInPropertiesList = service.currentSet.editor.fields.where((field:any) => {
					return field.value == undefined;

				}).toArray();

				fieldsDefinedInRDNButNotInPropertiesList.forEach((field:any) => {
					if (field.key == "Name" || field.key == "Latitude" || field.key == "Longitude" || field.key == "Id") {
						//-- for IoT.Thing records, these fields come from the main record, not the properties.
						field.value = row[field.key.toLowerCase()].value;
					}
				});
				console.log("fieldsDefinedInRDNButNotInPropertiesList = %O", fieldsDefinedInRDNButNotInPropertiesList);

				service.currentSet.editor.HasDynamicProperties = service.currentSet.editor.fields.where((field:any) => { return field.isDynamicProperty == true }).toArray().length > 0;

				console.log("this.currentSet.editor.HasDynamicProperties = " + service.currentSet.editor.HasDynamicProperties);

			}
			else {
				service.currentSet.editor.fields.forEach((field: any) => {
					Global.User.DebugMode && console.log(service.componentName + "field.key: " + field.key + ", row['" + field.key.replace("@", "") + "'] = %O", row[field.key.replace("@", "")]);
					var property = row[field.key.replace("@", "")];
					service.formatDefinedField(field, property);
				});
			}

			Global.User.DebugMode && console.log(service.componentName + "this.currentSet.editor.fields = %O", service.currentSet.editor.fields);
			service.editQuestions = service.currentSet.editor.fields;

			service.loadingEditForm = false;
		}
	}

	formatDefinedField(field:any, property:any) {
		if (field.type == "date") {
			if (property.value == null) {
				field.value = null;
			} else {
				field.value = new Date(property.value);
			}
		} else {
			if (field.controlType != "text-differences-comparison") {
				if (field.controlType == "slider-yes-no" || field.controlType == "slider-true-false" || field.controlType == "slider" || field.controlType == "true-false-button") {
					field.value = property?.value == undefined ? false : property?.value == "True" || property?.value == true || property?.value == 1 ? true : false;
				} else {
					field.value = property?.value;
				}
			}
		}
		if (field.buttonList && field.buttonList.parameters != null) {
			Global.User.DebugMode && console.log(this.componentName + "field.buttonList.parameters = %O", field.buttonList.parameters);
			field.buttonList.parameters.forEach((parameter: any) => {
				if (parameter.listValue != null) {
					Global.User.DebugMode && console.log(this.componentName + "parameter.listValue = " + parameter.listValue);
					Global.User.DebugMode && console.log(this.componentName + "this.currentSetData = %O", this.currentSetData);
					this.currentSetData.forEach((row: any) => {
						Global.User.DebugMode && console.log(this.componentName + "row = %O", row);
						Global.User.DebugMode && console.log(this.componentName + "row[parameter.listLookupField] = %O", row[parameter.listLookupField]);
						if (row[parameter.listLookupField].value == parameter.listLookupValue) {
							parameter.value = row[parameter.listValue].value;
						}
					});
					Global.User.DebugMode && console.log(this.componentName + "parameter.value = " + parameter.value);
				}
			});
		}
	}

	editRecordLegacy(row: any, type: string, event: any, index?: any) {
		if (event != null) {
			var cX = event.clientX;
			var screenX = event.screenX;
			var cY = event.clientY;
			var screenY = event.screenY;
			var coords1 = "client - X: " + cX + ", Y coords: " + cY;
			var coords2 = "screen - X: " + screenX + ", Y coords: " + screenY;

			this.currentXPosition = screenX;
			this.currentYPosition = screenY;
		}

		if (index != null) {
			this.currentIndex = this.currentSet.tableDisplay.entityName + "_" + index;
		}

		Global.User.DebugMode && console.log(this.componentName + "editingRecord: currentChildColumnLink = " + this.currentChildColumnLink + ", currentChildColumnLinkValue = " + this.currentChildColumnLinkValue);
		var service = this;
		this.loadingEditForm = true;
		Global.User.DebugMode && console.log(this.componentName + "edit record invoked. type = " + type + ", row = %O", row);
		Global.RDN.editingForm = true;
		this.rdnService.editingForm$.next(Global.RDN.editingForm);
		this.editFormTitle = type == "add" ? "Adding New " + this.currentSet.tableDisplay.entityDisplayName + " Record" : "Editing " + this.currentSet.tableDisplay.entityDisplayName + " Record";

		this.editFormOptions = {
			submitButtonText: type == "add" ? "Add New Record" : "Save Changes",
			saveValuesPerField: false,
			saveStoredProcedureName: this.currentSet.editor.saveStoredProcedureName,
			cancelButtonText: "Cancel"
		};

		if (type == "edit") {
			this.currentlyEditedRecord = row;
			//-- build field list for editing.
			var fieldList = this.currentSet.editor.fields
				.select((field: any) => {
					return field.key.replace("@", "");
				})
				.toArray()
				.join(",");

			var editStoredProcedureName = "API.RDN_EditSQLTableRecord @entityId=" + row["Id"].value + ", @entityName='" + this.currentSet.tableDisplay.entityName + "', @fieldList='" + fieldList + "', @UserId=" + Global.User.currentUser.Id; //-- this is the default stored procedure for editing the current record.
			if (service.currentSet.editor.editStoredProcedureName != null) {
				editStoredProcedureName = service.currentSet.editor.editStoredProcedureName + " @entityId=" + row["Id"].value + ", @UserId=" + Global.User.currentUser.Id; //-- this is if the developer wants to be more creative in editing the record (i.e. by joining other fields that might not be part of a single record edit.)
			} else {
				Global.User.DebugMode && console.log(this.componentName + "fieldList = " + fieldList);
			}
			Global.User.DebugMode && console.log(this.componentName + "editStoredProcedureName = " + editStoredProcedureName);

			this.dataService.SQLActionAsPromise(editStoredProcedureName).then((data: any) => {
				Global.User.DebugMode && console.log(service.currentSet.editor.editStoredProcedureName + " data = %O", data);
				service.currentSet.editor.fields.forEach((field: any) => {
					data.forEach((row: any) => {
						Global.User.DebugMode && console.log(this.componentName + "field.key: " + field.key + ", row['" + field.key.replace("@", "") + "'] = %O", row[field.key.replace("@", "")]);
						if (field.type == "date") {
							field.value = new Date(row[field.key.replace("@", "")]);
						} else {
							field.value = row[field.key.replace("@", "")];
						}
					});
				});
				Global.User.DebugMode && console.log(this.componentName + "service.currentSet.editor.fields = %O", service.currentSet.editor.fields);
				this.loadingEditForm = false;
			});
		} else {
			service.currentlyEditedRecord = null;
			service.currentSet.childColumnLink = service.currentChildColumnLink;
			service.currentSet.childColumnLinkValue = service.currentChildColumnLinkValue;
			service.currentSet.editor.fields.forEach((field: any) => {
				field.value = null;
				if (field.type == "parent-key-field") {
					if (field.key == service.currentChildColumnLink) {
						field.value = service.currentChildColumnLinkValue;
					}
				}
				if (field.key == "Id") {
					field.visible = false; //-- this is an insert of a record, so there's no need to display the Id field. --Kirk T. Sherer, July 7, 2021.
				}
			});
			Global.User.DebugMode && console.log(this.componentName + "currentSet: %O", service.currentSet);

			service.loadingEditForm = false;
		}
		service.currentStatus = "edit-record";
		service.currentStatus$.next(service.currentStatus);
	}

	deleteRecord(row: any) {
		var service = this;
		Global.User.DebugMode && console.log(this.componentName + "deleting record = %O", row);
		var nameOfRecord = row["Name"] != null ? row["Name"].value : null;

		var swalWithBootstrapButtons = swal.mixin({
			customClass: {
				confirmButton: "btn btn-danger",
				cancelButton: "btn btn-success"
			},
			buttonsStyling: false
		});
		swalWithBootstrapButtons
			.fire({
				title: "Are you sure?",
				html: "This will remove the " + (nameOfRecord != null ? "'<strong>" + nameOfRecord + "</strong>'" : "") + " record from the " + this.currentSet.tableDisplay.entityDisplayName + " list.  You won't be able to revert this.",
				showCancelButton: true,
				confirmButtonText: "Delete Record",
				cancelButtonText: "Cancel",
				reverseButtons: false
			})
			.then((result) => {
				if (result.value) {
					// logic for deleting dashboard goes here.
					var sqlStoredProcedureForDelete = "API.RDN_SetSQLRecordToDeletedStatus @tableName='" + this.currentSet.tableDisplay.entityName + "', @Id=" + row["Id"].value + ", @UserId=" + Global.User.currentUser.Id;
					if (this.currentSet.tableDisplay.deleteStoredProcedure != null) {
						Global.User.DebugMode && console.log(this.componentName + "Executing " + this.currentSet.tableDisplay.deleteStoredProcedure + " stored procedure for deletion ...");
						sqlStoredProcedureForDelete = this.currentSet.tableDisplay.deleteStoredProcedure + " @" + this.currentSet.tableDisplay.entityName.replace("dbo.", "") + "Id=" + row["Id"].value + ", @UserId=" + Global.User.currentUser.Id;
					} else {
						Global.User.DebugMode && console.log(this.componentName + "marking record for deletion...");
					}
					this.signalR.LogActivity("Executing RDN delete stored procedure: " + sqlStoredProcedureForDelete);
					this.dataService.SQLActionAsPromise(sqlStoredProcedureForDelete).then((data: any) => {
						try {
							var error = data
								.where((d: any) => {
									return d.ErrorMessage;
								})
								.toArray();
							if (error.length == 0) {
								var successful = data.length == 0 ? null : data.first();
								if (successful?.SuccessfulMessage == null) {
									service.utilityService.showToastMessageShared({
										type: "info",
										message: service.currentSet.tableDisplay.entityDisplayName + (nameOfRecord != null ? " '" + nameOfRecord + "'" : "") + " record has been DELETED.",
										title: service.currentSet.tableDisplay.label
									});
								} else {
									service.utilityService.showToastMessageShared({
										type: "info",
										message: data.first().SuccessfulMessage,
										title: service.currentSet.tableDisplay.label
									});
								}
							} else {
								swalWithBootstrapButtons.fire({
									title: "ERROR",
									html: error.first().ErrorMessage,
									showCancelButton: false,
									confirmButtonText: "OK",
									reverseButtons: false
								}); //-- pop up an alert with the error message.
							}
							service.executeStoredProcedure(service.latestFullStoredProcedureString);
							service.backToList();
						} catch (e) {
							service.executeStoredProcedure(service.latestFullStoredProcedureString);
							service.backToList();
						}
					});
				} else if (result.dismiss === swal.DismissReason.cancel) {
					this.signalR.LogActivity("Cancelled deletion of record.");
					service.utilityService.showToastMessageShared({
						type: "info",
						message: this.currentSet.tableDisplay.entityDisplayName + " record has NOT been deleted.",
						title: service.currentSet.tableDisplay.label
					});
				}
			});
	}

	copyRecord(row: any) {
		var service = this;
		var swalWithBootstrapButtons = swal.mixin({
			customClass: {
				confirmButton: "btn btn-danger",
				cancelButton: "btn btn-success"
			},
			buttonsStyling: false
		});
		swalWithBootstrapButtons
			.fire({
				title: "Are you sure?",
				html: "This will create a duplicate copy of the <strong>" + this.currentSet.tableDisplay.entityDisplayName + "</strong> record.  If this record is copied in error, you will have to delete the copy.",
				showCancelButton: true,
				confirmButtonText: "Copy Record",
				cancelButtonText: "Cancel",
				reverseButtons: false
			})
			.then((result) => {
				if (result.value) {
					if (this.currentSet.tableDisplay.copyStoredProcedure != null) {
						this.signalR.LogActivity("Executing RDN Copy stored procedure: " + this.currentSet.tableDisplay.copyStoredProcedure );
						this.dataService.SQLActionAsPromise(this.currentSet.tableDisplay.copyStoredProcedure + " @" + this.currentSet.tableDisplay.entityName.replace("dbo.", "") + "Id=" + row["Id"].value + ", @UserId=" + Global.User.currentUser.Id).then((data: any) => {
							var errorMessage = data
								.where((d: any) => {
									return d.ErrorMessage;
								})
								.toArray();
							if (errorMessage.length == 0) {
								service.utilityService.showToastMessageShared({
									type: "info",
									message: service.currentSet.tableDisplay.entityDisplayName + " record has been COPIED.",
									title: service.currentSet.tableDisplay.label
								});
							} else {
								swal.fire(errorMessage.first().ErrorMessage); //-- pop up an alert with the error message.
							}
							service.backToList();
						});
					}
				} else if (result.dismiss === swal.DismissReason.cancel) {
					this.signalR.LogActivity("Cancelled copy of record.");
					service.utilityService.showToastMessageShared({
						type: "info",
						message: service.currentSet.tableDisplay.entityDisplayName + " record has NOT been copied.",
						title: service.currentSet.tableDisplay.label
					});
				}
			});
	}

	backToList() {
		this.currentStatus = "view-list";
		this.currentStatus$.next(this.currentStatus);
		Global.RDN.editingForm = false;
		this.rdnService.editingForm$.next(Global.RDN.editingForm);
		this.signalR.LogActivity("Returned to the current RDN list.");
		// if (this.latestFullStoredProcedureString) {
		// 	// -- go back to the stored procedure that built out the current list, if it exists. --Kirk T. Sherer, June 16, 2021.
		// 	this.executeStoredProcedure(this.latestFullStoredProcedureString);
		// }
		// else {
		// 	if (this.currentSet.tableDisplay.sqlStoredProcedure != null) {
		// 		this.buildStoredProcedureString(this.currentSet.tableDisplay.sqlStoredProcedure, this.options, (this.currentSet.tableDisplay.expandedStoredProcedure ? null : this.currentSet.tableDisplay.displayColumns)); //-- if the stored procedure is already expanding the relational fields, then don't send the display columns. They are already listed in the stored procedure.
		// 	}
		// 	else {
		// 		if (this.currentSet.tableDisplay.entityName != null) {
		// 			this.buildStoredProcedureString("API.RDN_GetTop50RecordsForSQLTable @tableName='" + this.currentSet.tableDisplay.entityName + "'", this.options, this.currentSet.tableDisplay.displayColumns);
		// 		}
		// 	}
		// }
	}

	submitEditForm(submittedValues: any) {

		var service = this;
		// if (this.editFormOptions.saveStoredProcedureName != null && this.editFormOptions.saveValuesPerField == false)
		// {
			//Global.User.DebugMode && console.log(this.componentName + "submitEditForm: submittedValues = %O", submittedValues);
			var submittedValuesObject = JSON.parse(submittedValues);
			//Global.User.DebugMode && console.log(this.componentName + "submittedValuesObject = %O", submittedValuesObject);
			// Global.User.DebugMode && console.log(this.componentName + "Object.keys(submittedValuesObject) = %O", Object.keys(submittedValuesObject));
			var keys: Array<any> = Object.keys(submittedValuesObject);

			var displayFieldListArray = [];
			service.currentSet.tableDisplay.displayColumns.forEach((column: any) => {
				if (column.name != "actions") {
					displayFieldListArray.push(column.name);
				}
			});
			var displayFieldList = displayFieldListArray.join(",");

			var questions = this.currentSet.editor.fields;
			console.log("questions = %O", questions);
			var fieldListArray = [];
			//-- this is adding a new record, not editing an existing record.  Use the API.RDN_InsertRecordIntoSQLTable with the following parameters: @tableName, @fieldList (comma-separated list of field names), @fieldValues (comma-separated list with appropriate quotations for values in same field order), @UserId (user that is adding the record.)
			keys.forEach((key: any) => {
				questions.forEach((question: any) => {
					if (key == question.key && (question.type == "parent-key-field" || (question.controlType != "read-only" && question.type != "parent-key-field" && question.type != "primary-key-field"))) {
						fieldListArray.push(key);
					}
				});
			});
			var fieldList = fieldListArray.join(","); //-- don't get any read-only fields since we won't be sending read-only values to the stored procedure. --Kirk T. Sherer, June 14, 2021.

			console.log("field list = " + fieldList);
			var saveStoredProcedureName = this.currentSet.editor.saveStoredProcedureName; //-- this is the stored procedure you will run if you don't want the generic insert/update stored procedure to run. Typically this will be for unique save situations where there is more than one table involved. --Kirk T. Sherer, August 12, 2021.

			if (!this.currentlyEditedRecord && !saveStoredProcedureName) {
				//-- this is adding a new record, not editing an existing record or adding a new record with a specific stored procedure.  Use the API.RDN_InsertRecordIntoSQLTable with the following parameters: @tableName, @fieldList (comma-separated list of field names), @fieldValues (comma-separated list with appropriate quotations for values in same field order), @UserId (user that is adding the record.)
				var fieldValuesArray = [];
				var fieldValues = "";
				keys.forEach((key: any) => {
					var questions = this.currentSet.editor.fields;
					questions.forEach((question: any) => {
						if (key == question.key && (question.type == "parent-key-field" || (question.controlType != "read-only" && question.controlType != "read-only-textarea-large" && question.controlType != "read-only-textarea" && question.type != "parent-key-field" && question.type != "primary-key-field" && question.controlType != "text-differences-comparison"))) {
							var value = submittedValuesObject[key];
							if (typeof value == "object") {
								//--if we made it here with an entire object, then get the Id value of the object since it came from a selector. --Kirk T. Sherer, May 25, 2021.
								Global.User.DebugMode && console.log(this.componentName + "value = %O", value);
								if (key == "Id") {
									var idFieldValue = value["Id"];
									fieldValuesArray.push(idFieldValue); //submittedValuesObject[key].Id;
								} else {
									if (!value) {
										fieldValuesArray.push("null");
									} else {
										fieldValuesArray.push("'" + value.split("'").join("''") + "'");
									}
								}
							} else {
								if (value == null) {
									fieldValuesArray.push("null");
								} else {
									switch (question.controlType) {
										case "date":
										case "datetime":
											fieldValuesArray.push("'" + moment(value).format("MM/DD/YYYY HH:mm:ss") + "'");
											break;
										case "number":
											fieldValuesArray.push(isNaN(value) ? "null" : +value);
											break;
										case "slider":
										case "slider-yes-no":
										case "slider-true-false":
										case "true-false-button":
											//-- these are all boolean fields in SQL Server. Save a blank, text 0, numeric 0, or boolean false value as numeric 0 (bit=0 is false in SQL), else numeric 1 (bit=1 is true in SQL). --Kirk T. Sherer, January 18, 2023.
											if (value == "true" || value == "false" || value == true || value == false) {
												//-- this is a boolean field.  Set it as 1 for true or 0 for false.
												fieldValuesArray.push(value == "true" || value == true ? "1" : "0");
											}
											break;
										case "file-upload":
											fieldValuesArray.push(value == null ? "null" : "'" + value + "'");
											break;
										case "textbox":
										case "textarea":
										case "textarea-fancy":
										case "textarea-large":
										case "uib-button-multi-select":
											if (value == 0 || value == "0" || value == "") {
												if (key.indexOf("ValueWhenActive") != -1 || key.indexOf("OutputFalseValue") != -1 || key.indexOf("OutputTrueValue") != -1 ) {
													if (value == "") {
														fieldValuesArray.push("null");
													}
													else {
														fieldValuesArray.push("'" + value + "'"); //-- taking whatever is in the 'ValueWhenActive', OutputFalseValue, or OutputTrueValue field.  Do not reset to a null value when zero is entered. --Kirk T. Sherer, January 30, 2024.
													}
												}
												else {
													fieldValuesArray.push("null");
												}
											} else {
												if (key.indexOf("Id") != -1) {
													fieldValuesArray.push(value); //-- this is an ID field, and if it has a value, we don't need quotes around it. --Kirk T. Sherer, June 8, 2023.
												} else {
													if (typeof value.split == "function") {
														fieldValuesArray.push("'" + value.split("'").join("''") + "'");
													} else {
														fieldValuesArray.push("'" + value + "'");
													}
												}
											}
											break;
										default:
											fieldValuesArray.push(value);
											break;
									}
								}
							}
						}
					});
				});
				fieldValues = fieldValuesArray.join(",");
				console.log("field values = " + fieldValues);

				var sqlStoredProcedure = "API.RDN_InsertRecordIntoSQLTable";
				if (saveStoredProcedureName) {
					sqlStoredProcedure = saveStoredProcedureName;
					//-- build out the field list array as separate parameters with values for the specific stored procedure that isn't the generic API.RDN_InsertRecordIntoSQLTable since specific stored procedures will have the fields listed as parameters. --Kirk T. Sherer, August 23, 2021.
				}
				newParameterListAsString = "@tableName='" + this.currentSet.tableDisplay.entityName + "', @fieldList='" + fieldList + "', @fieldValues='" + fieldValues.split("'").join("''") + "', @sqlStoredProcedure='" + this.latestFullStoredProcedureString.split("'").join("''") + "', @UserId=" + Global.User.currentUser.Id;
				sqlStoredProcedure = sqlStoredProcedure + " " + newParameterListAsString;
			} else {
				//-- we're editing an existing record or we're inserting a new record with a specific stored procedure.  Using the API.RDN_UpdateSQLTable (or the specific stored procedure) by adding the list of fields and their corresponding values as a comma-separated list of SQL update statements. This will be sent as SQL to the destination stored procedure.
				//-- building a parameter list to attach to the end of the stored procedure to execute it. --Kirk T. Sherer, April 7, 2020.
				var submittedValuesAsString = submittedValues;
				var parameterListAsString = "";
				var countOfParameters = 1;

				keys.forEach((key: any) => {
					var questions = this.currentSet.editor.fields;
					questions.forEach((question: any) => {
						if (key == question.key && (question.type == "parent-key-field" || (question.controlType != "read-only" && question.controlType != "read-only-textarea-large" && question.controlType != "read-only-textarea" && question.type != "parent-key-field" && question.type != "primary-key-field" && question.controlType != "text-differences-comparison"))) {
							var value = submittedValuesObject[key];

							if (question.controlType == "uib-button-multi-select") {
								value = question.listOfValues
									?.where((d: any) => {
										return d.IsSelected == 1;
									})
									.select((vals: any) => {
										return vals.Id;
									})
									.toArray()
									.join(","); //-- multi-select will be comma-separated list of Id numbers to pass to the stored procedure. --Kirk T. Sherer, September 9, 2021.
							}

							if (this.currentSet.editor.saveStoredProcedureName != null) {
								if (key.indexOf("@") == -1) {
									//-- if the name of the field in the JSON object doesn't have an '@' in front of it, place one there since it will be a parameter in the save stored procedure. --Kirk T. Sherer, May 28, 2021.
									key = "@" + key;
								}
							} else {
								//-- if we don't have a saveStoredProcedureName defined, but the field keys are prefixed with a '@' for a stored procedure parameter, we have to remove them since
								//-- we're including the key/value pairs in the parameterListAsString variable. --Kirk T. Sherer, May 28, 2021.
								if (key.indexOf("@") != -1) {
									key = key.replace("@", "");
								}
							}

							Global.User.DebugMode && console.log(this.componentName + "key: " + key + ", value: " + value);
							parameterListAsString += key + "=";
							if (typeof value == "object" && value != null) {
								//--if we made it here with an entire object, then get the Id value of the object since it came from a selector. --Kirk T. Sherer, May 25, 2021.
								Global.User.DebugMode && console.log(this.componentName + "value = %O", value);
								var idFieldValue = value["Id"];
								parameterListAsString += idFieldValue; //submittedValuesObject[key].Id;
							} else {
								if (value == null) {
									parameterListAsString += "null";
								} else {
									switch (question.controlType) {
										case "date":
										case "datetime":
											parameterListAsString += "'" + moment(value).format("MM/DD/YYYY HH:mm:ss") + "'";
											break;
										case "number":
											parameterListAsString += isNaN(value) ? "null" : +value;
											break;
										case "slider":
										case "slider-yes-no":
										case "slider-true-false":
										case "true-false-button":
											//-- these are all boolean fields in SQL Server. Save a blank, text 0, numeric 0, or boolean false value as numeric 0 (bit=0 is false in SQL), else numeric 1 (bit=1 is true in SQL). --Kirk T. Sherer, January 18, 2023.
											if (value == "true" || value == "false" || value == true || value == false) {
												//-- this is a boolean field.  Set it as 1 for true or 0 for false.
												parameterListAsString += value == "true" || value == true ? "1" : "0";
											}
											break;
										case "file-upload":
											parameterListAsString += value == null ? "null" : "'" + value + "'";
											break;
										case "textbox":
										case "textarea":
										case "textarea-fancy":
										case "textarea-large":
										case "uib-button-multi-select":
											if (value == 0 || value == "0" || value == "") {
												if (key.indexOf("ValueWhenActive") != -1 || key.indexOf("OutputFalseValue") != -1 || key.indexOf("OutputTrueValue") != -1 ) {
													if (value == "") {
														parameterListAsString += "null";
													}
													else {
														parameterListAsString += "'" + value + "'"; //-- taking whatever is in the 'ValueWhenActive', OutputFalseValue, or OutputTrueValue field.  Do not reset to a null value when zero is entered. --Kirk T. Sherer, January 30, 2024.
													}
												}
												else {
													parameterListAsString += "null";
												}

											} else {
												console.log("key.indexOf(" + key + ") = " + key.indexOf("Id"));
												if (key.indexOf("Id") != -1) {
													parameterListAsString += value; //-- this is an ID field, and if it has a value, we don't need quotes around it. --Kirk T. Sherer, June 8, 2023.
												} else {
													if (typeof value.split == "function") {
														parameterListAsString += "'" + value.split("'").join("''") + "'";
													} else {
														parameterListAsString += "'" + value + "'";
													}
												}
											}
											break;
										default:3
											parameterListAsString += value;
											break;
									}
								}
							}

							if (countOfParameters < keys.length) {
								parameterListAsString += ",";
							}
							countOfParameters++;
						}
					});
				});

				Global.User.DebugMode && console.log(this.componentName + "parameterListAsString = " + parameterListAsString);
				var newParameterListAsString = parameterListAsString;
				newParameterListAsString = newParameterListAsString.replace(", ,", ",");
				if (newParameterListAsString.substr(newParameterListAsString.length - 1) == ",") {
					newParameterListAsString = newParameterListAsString.substr(0, newParameterListAsString.length - 1); // just exclude the trailing character since it's a comma.
				}

				var sqlStoredProcedure = "API.RDN_UpdateSQLTable";
				if (saveStoredProcedureName) {
					sqlStoredProcedure = saveStoredProcedureName + " " + newParameterListAsString + ", @Id=" + (this.currentlyEditedRecord && this.currentlyEditedRecord["Id"]?.value != null ? this.currentlyEditedRecord["Id"].value : "null") + ", @UserId=" + Global.User.currentUser.Id;
				} else {
					newParameterListAsString = "@tableName='" + this.currentSet.tableDisplay.entityName + "', @fieldUpdates='" + newParameterListAsString.split("'").join("''") + "', @Id=" + this.currentlyEditedRecord["Id"].value + ", @UserId=" + Global.User.currentUser.Id;
					sqlStoredProcedure = sqlStoredProcedure + " " + newParameterListAsString;
				}

				Global.User.DebugMode && console.log(this.componentName + "sqlStoredProcedure = " + sqlStoredProcedure);
			}

			if (this.currentSet.setName == "Person" && submittedValuesObject["OktaEnabled"] == 0) {
				//-- If this is a Person edit and we haven't set them up in Okta yet, we need to add them to Okta on Prod and Test. --Kirk T. Sherer, March 7, 2025.
				try {
					console.log("submittedValuesObject = %O", submittedValuesObject);

					var emailAddress = submittedValuesObject["Email"].replace(/\s/g, "");
					var userObject = {
						"FirstName": submittedValuesObject["GivenName"],
						"LastName": submittedValuesObject["FamilyName"],
						"Email": emailAddress
					};

					this.dataService.updateOktaUserProfile(userObject).then((data:any) => {
						console.log("Okta data returned: %O", data);
						if (data.body != null) {
							sqlStoredProcedure = sqlStoredProcedure.replace("OktaEnabled=0","OktaEnabled=1");
							submittedValuesObject["OktaEnabled"] = 1;
						}
						this.submitStoredProcedure(sqlStoredProcedure); //-- only executing the stored procedure when we're finished updating Okta for this person's email address on both Okta Test and Okta Prod. --Kirk T. Sherer, March 12, 2025.
					});
				}
				catch (error) {
					console.log("Error in searching Okta server for user: %O", error);
				}

			}
			else {
				this.submitStoredProcedure(sqlStoredProcedure); //-- go ahead and submit the stored procedure since this isn't related to the Person record. --Kirk T. Sherer, March 7, 2025.
			}

		//}

	}

	private submitStoredProcedure(sqlStoredProcedure: string) {
		var service = this;
		this.signalR.LogActivity("Executing add/update record stored procedure: " + sqlStoredProcedure);
		//--insert new record or update existing record here.
		this.dataService.SQLActionAsPromise(sqlStoredProcedure).then((data: any) => {
			Global.User.DebugMode && console.log(this.componentName + "data was submitted.");
			var possibleErrorMessage = data.firstOrDefault((d: any) => {
				return d.ErrorMessage;
			});
			if (possibleErrorMessage) {
				var actualErrorMessage = data.first((d: any) => {
					return d;
				}).ErrorMessage;
				swal.fire({
					title: "<div><p style='color: rgb(84, 84, 84); font-size: 18px; font-weight: 400; text-align: left; margin-bottom: 10px;'>Error saving " + service.currentSet.tableDisplay.entityName + " record</p></div>",
					html: "<p style='text-align: left; color: rgb(84, 84, 84);'>" + actualErrorMessage + (Global.User.isAdmin ? "<br /><br />" + sqlStoredProcedure : "") + "</p>"
				});
			} else {
				console.log((!this.currentlyEditedRecord ? "Added " : "Edited ") + "record: data = %O", data);
				this.signalR.LogActivity("Successfully " + (!this.currentlyEditedRecord ? "Added " : "Edited ") + "record.");
				// if (!this.currentlyEditedRecord) {
				// 	var row = data.first();
				// 	var originalRow = row;
				// 	//Global.User.DebugMode && console.log(service.componentName + "originalRow = %O", originalRow);
				// 	service.currentSet.tableDisplay.displayColumns.forEach((column: any) => {
				// 		//Current State
				// 		//row[column.name] = a primitive value;
				// 		//
				// 		//Reformat to:
				// 		row[column.name] = {
				// 			value: row[column.name],
				// 			rdnRecursionSet: null
				// 		};

				// 		if (column.type == 'set') {

				// 			var currentContainingDivId = ++Global.RDN.maxId;

				// 			var recursiveSet = {
				// 				containingDivId: currentContainingDivId,
				// 				parentColumnLink: column.parentColumnLink,
				// 				childColumnLink: column.childColumnLink,
				// 				childColumnLinkValue: originalRow[column.parentColumnLink].value != null ? originalRow[column.parentColumnLink].value : originalRow[column.parentColumnLink], //--original row contains the row the way it came from SQL Server.
				// 				setName: column.setName
				// 			};

				// 			row[column.name].rdnRecursionSet = recursiveSet;
				// 			Global.RDN.recursedSets.push(recursiveSet);
				// 		}

				// 	});
				// 	service.currentSetData.push(row);
				// 	service.currentSetDataCountOfRecords++;
				// }
				// else {
				// 	var editedRow = data.first();
				// 	Global.User.DebugMode && console.log(service.componentName + "editForm --> editedRow = %O", editedRow);
				// 	Global.User.DebugMode && console.log(service.componentName + "editing record --> this.currentSet = %O", service.currentSet);
				// 	Global.User.DebugMode && console.log(service.componentName + "editing record --> this.currentSetData = %O", service.currentSetData);
				// 	var editedRowInDataSet = service.currentSetData.first((d:any) => { return d['Id'].value == editedRow.Id });
				// 	service.currentSet.tableDisplay.displayColumns.forEach((column: any) => {
				// 		//Current State
				// 		//row[column.name] = a primitive value;
				// 		//
				// 		//Reformat to:
				// 		if (column.type != 'set') {
				// 			editedRowInDataSet[column.name] = {
				// 				value: editedRow[column.name],
				// 				rdnRecursionSet: null
				// 			};
				// 		}
				// 	});
				// }
				// Global.User.DebugMode && console.log(service.componentName + "editing record --> this.currentSet.setName = " + this.currentSet.setName);
				// service.backToList();

				this.currentStatus = "view-list";
				Global.RDN.editingForm = false;

				if (this.currentSet?.tableDisplay?.dataCacheCollection != null) {
					service.formatDataCacheCollection();
				}
				else {
					this.currentStatus$.next(this.currentStatus);
					this.rdnService.editingForm$.next(Global.RDN.editingForm);
					service.executeStoredProcedure(service.latestFullStoredProcedureString);
				}
			}
		});
	}

	public viewFile(fileImage: any) {
		Global.User.DebugMode && console.log(this.componentName + "trying to view the file... file = %O", fileImage);
		var url = fileImage.viewUrl;

		var intHSize = window.screen.availWidth * 0.7;
		var intVSize = window.screen.availHeight * 0.7;
		var intTop = (window.screen.availHeight - intVSize) / 2;
		var intLeft = (window.screen.availWidth - intHSize) / 2;
		var sngWindow_Size_Percentage = 0.85;

		var strFeatures = "";
		intHSize = window.screen.availWidth * sngWindow_Size_Percentage * 0.6;
		intVSize = window.screen.availHeight * sngWindow_Size_Percentage;
		intTop = (window.screen.availHeight - intVSize) / 2;
		intLeft = (window.screen.availWidth - intHSize) / 2;
		strFeatures = "top=" + intTop + ",left=" + intLeft + ",height=" + intVSize + ",width=" + intHSize + ", scrollbars=yes, resizable=yes, location=no";

		var SRWindow = window.open(url, "SRWindow", strFeatures);
	}

	drop(event: CdkDragDrop<string[]>, list: any) {
		var service = this;
		moveItemInArray(list, event.previousIndex, event.currentIndex);
		Global.User.DebugMode && console.log(this.componentName + "event.previousIndex = " + event.previousIndex + ", event.currentIndex = " + event.currentIndex);
		Global.User.DebugMode && console.log(this.componentName + "list = %O", list);
		let ordinalValue = 1;
		var countOfItemsInList = 0;
		var newList = null;
		var finishedWithUpdate = false;
		var requestJSON: any = [];
		list.forEach((item: any) => {
			item.Ordinal.value = ordinalValue;
			ordinalValue++;
			var sqlStatement = "API.RDN_UpdateOrdinalInList @UserId=" + Global.User.currentUser.Id + ", @tableName='" + service.currentSet.tableDisplay.entityName + "', @Id=" + item.Id.value + ", @OrdinalValue=" + item.Ordinal.value;
			var listSQLObject = {
				label: "List-" + item.Ordinal.value,
				sqlStatement: sqlStatement
			};
			requestJSON.push(listSQLObject);
			countOfItemsInList++;
		});
		Global.User.DebugMode && console.log(this.componentName + "List drag-and-drop requestJSON = %O", requestJSON);
		this.dataService.SQLMultiAction(requestJSON).then((data: any) => {
			Global.User.DebugMode && console.log(this.componentName + "SQLMultiAction for requestJSON = %O", data);
			var collections: any = data;
			collections.forEach((collection: any) => {
				if (collection.label == "List-" + countOfItemsInList) {
					newList = collection.data; //-- get last update of the items for the final list.
					service.executeStoredProcedure(service.latestFullStoredProcedureString); //-- only when we're finished updating all of the items for the new ordinal value do we need to notify the RDN to redisplay the list.
				}
			});
		});
	}

	openFileImageUploadWindow(entityType: string, title: string, entityId?: number) {
		var service = this;

		if (entityId == undefined) {
			entityId = null;
		}

		var sqlStatement = "API.FileImageManagement @Type='List', @EntityType='" + entityType + "', @EntityId=" + entityId + ", @UserId=" + Global.User.currentUser.Id;
		this.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
			Global.User.DebugMode && console.log(this.componentName + ": " + sqlStatement + " = %O", data);
			this.fileImageData = data;
			this.FileImageLibraryControlObject = {
				imageKeys: this.fileImageData.filter((i: any) => {
					return i.ImageKey;
				}),
				//---B
				removeUploadFunction(deletedImageKey: string) {
					service.dataService.SQLActionAsPromise("API.FileImageManagement @Type='Delete', @EntityType='" + entityType + "', @EntityId=" + entityId + ", @ImageKey='" + deletedImageKey + "', @UserId=" + Global.User.currentUser.Id).then((deletedEntity: any) => {
						service.fileImageData.splice(deletedEntity);
					});
				},
				//---B
				addUploadFunction(newImageKey: string) {
					service.dataService.SQLActionAsPromise("API.FileImageManagement @Type='Add', @EntityType='" + entityType + "', @EntityId=" + entityId + ", @ImageKey='" + newImageKey + "', @UserId=" + Global.User.currentUser.Id).then((newEntity: any) => {
						service.fileImageData.push(newEntity);
					});
				},
				mode: null,
				listTitle: title,
				entityId: entityId,
				entityType: entityType,
				closeUploadWindowFunction() {
					service.FileImageLibraryControlObject = null;
				}
			};

			this.openFileUploadDialog();
		});
	}

	openFileUploadDialog(): void {
		var innerWidth = window.innerWidth;
		const dialogRef = this.dialog.open(FileUploadListComponent, {
			width: "70%",
			height: "70%"
		});

		dialogRef.componentInstance.fileImageLibraryControlObject = this.FileImageLibraryControlObject;

		dialogRef.afterClosed().subscribe((result) => {
			Global.User.DebugMode && console.log(this.componentName + ": The File Upload dialog was closed");
			this.getCountOfFilesUploaded(this.FileImageLibraryControlObject.entityId, this.FileImageLibraryControlObject.entityType);
		});
	}

	getCountOfFilesUploaded(entityId: number, entityType: string) {
		var sqlStatement = "API.FileImageManagement @Type='Count', @EntityType='" + entityType + "', @EntityId=" + entityId + ", @UserId=" + Global.User.currentUser.Id;
		this.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
			var listOfFiles = data;
			this.countOfFilesUploaded = listOfFiles.length > 0 ? listOfFiles.first().CountOfFilesUploaded : 0;
		});
	}
}
