import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from 'projects/shared-lib/src/lib/services/authentication.service';
import { DataService } from 'projects/shared-lib/src/lib/services/data.service';
import { SecurityService } from 'projects/shared-lib/src/lib/services/security.service';
import { Global } from 'projects/shared-lib/src/lib/_constants/global.variables';




@Component({
	selector: 'app-authentication',
	templateUrl: 'authentication.component.html',
})
export class AuthenticationComponent implements OnInit {
	constructor(
		private securityService: SecurityService,
		private authenticationService: AuthenticationService,
		private router: Router,
		public dataService: DataService
	) {}

	ngOnInit() {
		//-- if we made it here on a route, we should re-authenticate the user.
		console.log('re-authenticating the user...');
		this.securityService.authenticateUser();
	}
}
