import { Injectable, InjectionToken } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Global } from 'projects/shared-lib/src/lib/_constants/global.variables';
import { Observable } from "rxjs";

const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');
@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor() { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const externalUrl = route.paramMap.get('externalUrl');
    if (externalUrl != null) {
      try {
        //window.open("https://www.mlb.com/astros", '_self');
        //document.location.href = externalUrl;
        window.open(externalUrl, '_self');
      }
      catch (error) {
        console.log("Error going to externalUrl '" + externalUrl + "'... Error message: " + error);
      }
    }
    else {
      return this.checkLoggedIn(state.url);
    }
  }

  checkLoggedIn(url: string): boolean {
    if (Global?.User?.isLoggedIn) {
      return true;
    }
	else
	{
		return false;
	}
  }
}
