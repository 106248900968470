<mat-progress-bar *ngIf="global.serverLoading" appCustomProgressbar mode="indeterminate" style="display: inline-block; position: absolute; z-index: 999"></mat-progress-bar>

<nav
	class="navbar navbar-expand-lg navbar-absolute"
	[ngClass]="{
		'bg-white': isCollapsed === false,
		'navbar-transparent': isCollapsed === true
	}"
>
	<div class="container-fluid" style="padding-right: 0">
		<div class="navbar-wrapper">
			<div class="navbar-minimize d-inline">
				<button class="minimize-sidebar jbt-btn" tooltil="Sidebar toggle" placement="right" (click)="minimizeSidebar()" style="padding-left: 12px; padding-right: 12px;">
					<i class="tim-icons icon-align-center visible-on-sidebar-regular"> </i>
					<i class="tim-icons icon-bullet-list-67 visible-on-sidebar-mini"> </i>
				</button>
			</div>
			<div class="navbar-toggle d-inline">
				<button class="navbar-toggler" type="button" (click)="sidebarOpen()">
					<span class="navbar-toggler-bar bar1"> </span>
					<span class="navbar-toggler-bar bar2"> </span>
					<span class="navbar-toggler-bar bar3"> </span>
				</button>
			</div>
			<a class="navbar-brand" href="javascript:void(0)"> {{ getTitle() }} </a>
		</div>
		<button aria-label="Toggle navigation" class="navbar-toggler" [attr.aria-expanded]="!isCollapsed" (click)="isCollapsed = !isCollapsed" id="navigation" type="button">
			<span class="navbar-toggler-bar navbar-kebab"> </span>
			<span class="navbar-toggler-bar navbar-kebab"> </span>
			<span class="navbar-toggler-bar navbar-kebab"> </span>
		</button>
    	<div (click)="securityService.openSnackBarMessage(broadcastMessage)" class="broadcast-message" *ngIf="broadcastMessage != null" [title]="broadcastMessage.Description">NOTE: {{broadcastMessage.Message}}</div>
		<div class="navbar-collapse" [collapse]="isCollapsed" id="navigation">
			<ul class="navbar-nav ml-auto">
				<li class="welcome">
					<a *ngIf="hours">{{ formattedTime?.includes("AM") ? "Good morning" : formattedTime?.includes("PM") && (hours < 6 || hours == 12) ? "Good afternoon" : "Good evening" }}, {{ firstName }}.</a>
				</li>
			</ul>
			<ul class="navbar-nav">


				<li class="dropdown nav-item" dropdown placement="bottom left" style="cursor: pointer;">
					<button [matMenuTriggerFor]="menuNavbar" style="background-color: transparent; border: 0px;">
						<div class="company-logo" [matBadge]="global.User.issueNotificationCount" [matBadgeHidden]="global.User.issueNotificationCount === 0" matBadgeOverlap="false" matBadgePosition='below before' matBadgeColor="warn">
							<img alt="Oshkosh AeroTech Logo" src="{{ CompanyTinyLogoImage }}" height="30px" width="25px" />
						</div>
						<b class="caret d-none d-lg-block d-xl-block"> </b>
					</button>
                    <mat-menu #menuNavbar="matMenu">
						<button mat-menu-item (click)="openDialog('userSettings')">
							 Settings
						</button>
						<button mat-menu-item title="Change your iOPS password." (click)="sendPasswordToken()">
							 Change Password
						</button>
						<button mat-menu-item title="Edit or view your personal information."  (click)="openDialog('personalInformation')">
							Personal Information
						</button>
						<button mat-menu-item title="Edit or view your alert settings."  (click)="openDialog('AlertSettings')">
							Alert Settings
						</button>
						<button mat-menu-item title="Edit or view your personal alert escalations."  (click)="openDialog('PersonalAlertEscalation')">
							Alert Escalation Settings
						</button>
						<!-- <button mat-menu-item title="View the list of Training Videos. {{ countOfTrainingVideosUploaded > 0 ? countOfTrainingVideosUploaded + ' video' + (countOfTrainingVideosUploaded > 1 ? 's' : '') + ' uploaded currently.' : '' }} "  (click)="openDialog('trainingVideos')">
							 Training Videos
						</button> -->
						<button mat-menu-item [routerLink]="['/layout', 'issues']"  routerLinkActive="active"   title="Log an issue with this application." >
                            <div [ngClass]="{'category-link': global.User.issueNotificationCount > 0, 'active' : global.User.issueNotificationCount > 0}">Customer Support</div>
						</button>

						<button mat-menu-item title="iOPS Application Release Notes."  (click)="openDialog('releaseNotes')">
							Release Notes
						</button>
						<button mat-menu-item title="Log out of this application."  [routerLink]="['/user/logout']">
							Log out
						</button>
					</mat-menu>
				</li>


				<!-- <li
          class=" fixed-plugin"
          style="top:0; padding:0; position: relative; background-color:transparent; width:unset "
        >
          <div dropdown [insideClick]="true">
            <a
              id="button-basic"
              data-toggle="dropdown"
              dropdownToggle
              class="dropdown-toggle"
              aria-controls="dropdown-basic"
            >
              <i class=" fa fa-cog fa-2x"> </i>
            </a>

            <ul
              id="dropdown-basic"
              *dropdownMenu
              class="dropdown-menu"
              role="menu"
              aria-labelledby="button-basic"
            >
              <li class=" header-title">Sidebar Background</li>
              <li class=" adjustments-line">
                <a
                  class=" switch-trigger background-color"
                  href="javascript:void(0)"
                >
                  <div class=" badge-colors text-center">
                    <span
                      class=" badge filter badge-primary"
                      [ngClass]="{ active: sidebarColor === 'primary' }"
                      (click)="changeSidebarColor('primary')"
                    >
                    </span>
                    <span
                      class=" badge filter badge-info"
                      [ngClass]="{ active: sidebarColor === 'blue' }"
                      (click)="changeSidebarColor('blue')"
                    >
                    </span>
                    <span
                      class=" badge filter badge-success"
                      [ngClass]="{ active: sidebarColor === 'green' }"
                      (click)="changeSidebarColor('green')"
                    >
                    </span>
                    <span
                      class="badge filter badge-warning"
                      [ngClass]="{ active: sidebarColor === 'orange' }"
                      (click)="changeSidebarColor('orange')"
                    ></span>
                    <span
                      class=" badge filter badge-danger"
                      [ngClass]="{ active: sidebarColor === 'red' }"
                      (click)="changeSidebarColor('red')"
                    >
                    </span>
                  </div>
                  <div class=" clearfix"></div>
                </a>
              </li>
              <li class="header-title">Sidebar Mini</li>
              <li class="adjustments-line">
                <div class="togglebutton switch-sidebar-mini">
                  <span class="label-switch">OFF</span>
                  <bSwitch
                    [switch-on-color]="'primary'"
                    [switch-off-text]="' '"
                    [switch-on-text]="' '"
                    [(ngModel)]="state"
                    (onChangeState)="onChange($event)"
                    (click)="onChange($event)"
                  ></bSwitch>
                  <span class="label-switch label-right">ON</span>
                </div>
              </li>
              <li class=" adjustments-line text-center color-change">
                <div class="togglebutton switch-sidebar-mini">
                  <span class="label-switch"> LIGHT MODE </span>
                  <bSwitch
                    [switch-on-color]="'primary'"
                    [switch-off-text]="' '"
                    [switch-on-text]="' '"
                    [(ngModel)]="dashboardColor"
                    (onChangeState)="onChangeDashboardColor($event)"
                    (click)="onChangeDashboardColor($event)"
                  ></bSwitch>
                  <span class="label-switch label-right"> DARK MODE </span>
                </div>
              </li>
            </ul>
          </div>
        </li> -->
				<li class="separator d-lg-none"></li>
			</ul>
		</div>
	</div>
</nav>
<div aria-hidden="true" aria-labelledby="searchModal" bsModal class="modal modal-search fade" #searchModal="bs-modal" id="searchModal" role="dialog" tabindex="-1">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<input class="form-control" id="inlineFormInputGroup" placeholder="SEARCH" type="text" />
				<button aria-label="Close" class="close" (click)="searchModal.hide()" data-dismiss="modal" type="button">
					<i class="tim-icons icon-simple-remove"> </i>
				</button>
			</div>
		</div>
	</div>
</div>
