import { Component, OnInit, HostListener, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";



import { BehaviorSubject } from 'rxjs';
import { SecurityService } from 'projects/shared-lib/src/lib/services/security.service';
import { DataService } from 'projects/shared-lib/src/lib/services/data.service';
import { Global } from 'projects/shared-lib/src/lib/_constants/global.variables';
import { UtilityService } from 'projects/shared-lib/src/lib/services/utility.service';
import { IGlobal } from "projects/shared-lib/src/lib/_models/global.model";
import { colResizeIcon } from "@progress/kendo-svg-icons";

const misc: any = {
	sidebar_mini_active: true,
};

@Component({
	selector: 'app-layout',
	templateUrl: './layout.component.html',
	styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
	private checkUserLogin$: any;
	public isLoggedIn: boolean;
	public global: IGlobal = Global;
	public componentName: string = "layout: ";
	public finishedLayoutSetup: boolean = false;

	constructor(
		public router: Router,
		public toastr: ToastrService,
		public securityService: SecurityService,
		public dataService: DataService,
		private utilityService: UtilityService
	) {}

	@HostListener('window:scroll', ['$event'])
	showNavbarButton = () => {
		const mainPanel: any = document.getElementsByClassName('main-panel')[0];
		const navbarMinimize: any = document.getElementsByClassName(
			'navbar-minimize-fixed'
		)[0];

		if (
			document.documentElement.scrollTop > 50 ||
			document.scrollingElement.scrollTop > 50 ||
			mainPanel.scrollTop > 50
		) {
			navbarMinimize.style.opacity = 1;
		} else if (
			document.documentElement.scrollTop <= 50 ||
			document.scrollingElement.scrollTop <= 50 ||
			mainPanel.scrollTop <= 50
		) {
			navbarMinimize.style.opacity = 0;
		}
	};

	ngOnInit() {
		if (!this.securityService.currentUserIsLoggedIn) {
			this.router.navigate(['user/login']);
		}

		const currentUser = this.securityService.getCurrentUser();

		Global.User.DebugMode && console.log(this.componentName + 'currentUser = %O', currentUser);

		this.checkUserLogin$ = new BehaviorSubject(Global.User.isLoggedIn);
		this.checkUserLogin$.subscribe(
			(isLoggedIn: boolean) => {
				if (isLoggedIn) {
					Global.User.DebugMode && console.log(this.componentName + ' misc = %O', misc);
					this.dataService.initializeSiteWithUserPreferences();
					this.showNavbarButton();
					this.finishedLayoutSetup = true;
					this.isLoggedIn = true;
				}
				else {
					this.finishedLayoutSetup = false;
					this.isLoggedIn = false;
				}
			},
			(err) =>
				Global.User.DebugMode &&
				console.log(`Error checking user login: ${err}`)
			
		);

		this.dataService.menuColorChange$.subscribe((color:string) => {
			this.dataService.initializeSiteWithUserPreferences();
		});
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log('ngOnDestroy invoked...');
		this.checkUserLogin$ && this.checkUserLogin$.unsubscribe();
		this.dataService.menuColorChange$.unsubscribe();
		this.finishedLayoutSetup = false;
		this.isLoggedIn = false;
	} 

	minimizeSidebar() {
		this.dataService.toggleSidebarMini();
	}

	showSidebarMessage(message) {
		this.utilityService.showToastMessageShared({
			type: 'info',
			message:
				'<span data-notify="icon" class="tim-icons icon-bell-55"></span>',
			title: message,
			override: {
				timeOut: 4000,
				closeButton: true,
				enableHtml: true,
				toastClass: 'alert alert-danger alert-with-icon',
				positionClass: 'toast-top-right',
			},
		});
		// this.toastr.show('<span data-notify="icon" class="tim-icons icon-bell-55"></span>', message, {
		// 	timeOut: 4000,
		// 	closeButton: true,
		// 	enableHtml: true,
		// 	toastClass: "alert alert-danger alert-with-icon",
		// 	positionClass: "toast-top-right",
		// });
	}
}
