import { InjectionToken, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule, ActivatedRouteSnapshot } from "@angular/router";

import { detailedRoutes } from "./_constants/detailed.routes";
const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');
// const routes: Routes = [
//   {
//     path: "user",
//     loadChildren: "./user/user.module#UserModule"
//   },
//   {
//     path: "layout",
//     loadChildren: "./_layout/layout.module#LayoutModule"
//   },
//   {
//     path: "",
//     redirectTo: "authentication",
//     pathMatch: "full"
//   }//,
//   // {
//   //   path: "**",
//   //   redirectTo: "authentication"
//   // }
// ];

@NgModule({
	imports: [
		CommonModule,
		RouterModule.forRoot(detailedRoutes, {
			useHash: false,
			scrollPositionRestoration: "enabled",
			anchorScrolling: "enabled",
			scrollOffset: [0, 64],
			enableTracing: false,
		}),
	],
	exports: [RouterModule],
	providers: [
		{
			provide: externalUrlProvider,
			useValue: (route: ActivatedRouteSnapshot) => {

				const externalUrl = route.paramMap.get('externalUrl');
				window.open(externalUrl, '_self');
			},
		},
	]
})
export class AppRoutingModule {}
