import { DashboardComponent } from "./dashboard/dashboard.component";
import { RecipesComponent } from "./advanced/recipes/recipes.component";
import { SystemTablesComponent } from "./advanced/system-tables/system-tables.component";
import { LayoutComponent } from "./layout.component";
import { OktaAuthGuard } from "@okta/okta-angular";

export const layoutRoutes = [
  {
    path: "",
    component: LayoutComponent,
	canActivate: [OktaAuthGuard],

  },
  {
    path: "",
    children: [
      {
        path: "dashboard/:id",
        component: DashboardComponent,
        outlet: "dashboard-area",
        // children: [
        //   {   path: 'widget/:id', component: WidgetComponent }
        // ]
      },
      {
        path: "recipes",
        outlet: "dashboard-area",
        component: RecipesComponent,
      },

      {
        path: "tables",
        outlet: "dashboard-area",
        component: SystemTablesComponent,
      },
    ],
  },
];
