import { Component, Input, AfterViewInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { GridSettings } from '../../_models/grid-settings.interface';
import { process, State, GroupDescriptor } from '@progress/kendo-data-query';
import { Global } from '../../_constants/global.variables';

@Component({
	selector: 'lib-training-lessons',
	templateUrl: './training-lessons.component.html',
	styleUrls: ['./training-lessons.component.scss'],
})
export class TrainingLessonsComponent implements AfterViewInit {
	@Input() public category: any;
	public gridSettings: GridSettings = {
		state: {
			skip: 0,
			filter: {
				logic: 'and',
				filters: [],
			},
		},

		columnsConfig: [],
	};
	isLoading: boolean;
	private videoKeyURL: string = Global.Video.serverArchiveUrl;
	private accessToken: string = Global.User.currentUser.AuthenticationToken;

	// 	CreationDate
	// :
	// Thu Dec 08 2022 09:07:17 GMT-0600 (Central Standard Time) {}
	// CreationDateMS
	// :
	// 1670512037230
	// CreatorUser
	// :
	// "Dylan Richard"
	// Description
	// :
	// "This course will teach you the basics of IOPS showing you dashboard setup, including dashboard options, widget control and others."
	// Id
	// :
	// 1
	// LastModifiedDate
	// :
	// Fri Dec 09 2022 08:58:40 GMT-0600 (Central Standard Time) {}
	// LastModifiedDateMS
	// :
	// 1670597920130
	// LastModifiedUser
	// :
	// "Dylan Richard"
	// Name
	// :
	// "Introduction to IOPS "

	constructor(private dataService: DataService) {}

	ngAfterViewInit(): void {
		this.isLoading = true;
		console.log(this.category);
		var sql =
			'API.RDN_GetTrainingCourseLessons @TrainingCourseId = ' +
			this.category.Id;

		this.dataService.SQLActionAsPromise(sql).then((dataObject: any) => {
			if (dataObject) {
				console.log(dataObject);
				dataObject.forEach((element: any) => {
					if (element.VideoServerFilename) {
						element.viewUrl =
							this.videoKeyURL +
							element.VideoServerFilename +
							'?accessToken=' +
							encodeURIComponent(this.accessToken);
					} else {
						element.viewUrl = null;
					}
				});
				this.gridSettings.gridData = process(
					dataObject,
					this.gridSettings.state
				);
				this.isLoading = false;
			}
		});
	}

	loadVideo(lesson: any) {
		if (!lesson.edit) {
			Global.User.DebugMode &&
				console.log('trying to view the file... file = %O', lesson);
			var url = lesson.viewUrl;

			var intHSize = window.screen.availWidth * 0.7;
			var intVSize = window.screen.availHeight * 0.7;
			var intTop = (window.screen.availHeight - intVSize) / 2;
			var intLeft = (window.screen.availWidth - intHSize) / 2;
			var sngWindow_Size_Percentage = 0.85;

			var strFeatures = '';
			intHSize =
				window.screen.availWidth * sngWindow_Size_Percentage * 0.6;
			intVSize = window.screen.availHeight * sngWindow_Size_Percentage;
			intTop = (window.screen.availHeight - intVSize) / 2;
			intLeft = (window.screen.availWidth - intHSize) / 2;
			strFeatures =
				'top=' +
				intTop +
				',left=' +
				intLeft +
				',height=' +
				intVSize +
				',width=' +
				intHSize +
				', scrollbars=yes, resizable=yes, location=no';

			var SRWindow = window.open(url, 'SRWindow', strFeatures);
		}
	}
}
